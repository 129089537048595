
import React from 'react';
import styled from 'styled-components';
import { Form as FormikForm, Field as FormikField } from 'formik';
import { sizes } from '../../../styles/variables';

import { LongButton } from './Button';

export const Form = styled(FormikForm)``;

export const Label = styled.label`
  color: ${(props) => props.theme.colors.greyFont};
  margin-bottom: 10px;
`;

export const Input = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray400};
  padding: 10px 7px;
  box-sizing: border-box;
  background: transparent;
  position: relative;

  :focus {
    outline: none;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
  }
`;

export const Field = (props) => (
  /* eslint-disable */
  <FormikField name={props.name}>
    {() => <Input name={props.name} {...props} />}
  </FormikField>
  /* eslint-enable */
);

export const Checkbox = styled.div`
  width: 100%;

  label {
    display: flex;
  }

  input {
    width: 14px;
    height: 14px;
    // margin-top: 2%;
    margin-right: 1%;
  }
`;

export const FileUpload = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  z-index: 5;
`;

export const UploadBox = styled.div`
  flex: 0 0 50%;
  padding: 15px 0;
  border: 1px dashed ${(props) => props.theme.colors.gray200};

  span {
    text-align: center;
    display: block;

    :last-child {
      font-weight: ${(props) => props.theme.font.weight.bold};
    }
  }

  input {
    display: none;
  }
`;

export const UploadedIconWrapper = styled.div`
  flex: 0 0 50%;
  padding-left: 5%;

  @media (max-width: ${sizes.mdMax}) {
    text-align: center;
  }
`;

export const UploadedIcon = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(params) => params.src});
  background-size: contain;
  background-repeat: no-repeat;

  @media (max-width: ${sizes.mdMax}) {
    background-position: center;
  }
`;

export const Hint = styled.p`
  margin: 0;
  color: ${(props) => props.theme.colors.greyFont};
  line-height: 3rem;
`;

export const Submit = styled(LongButton)`
  margin-top: 50px;
  float: right;
`;

export const Error = styled.div`
  background-color: ${(props) => props.theme.colors.errorLight};
  border: 1px solid ${(props) => props.theme.colors.errorDark}40;
  color: ${(props) => props.theme.colors.errorDark};
  position: relative;
  padding: 8px 10px;
  margin: 10px 0px;
`;

export const WarningText = styled.div`
  font-size: .8375em;
  color: #8f8f8f;
  position: relative;
  margin-left: 12px;
`;
