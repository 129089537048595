export const getUserInterface = () => {
  const role = (process.env.APPLICATION_USER_INTERFACE || 'doctor').toLowerCase();

  if (role !== 'patient') {
    return 'doctor';
  }
  return role;
};

export const useApplicationInterface = () => {
  const appInterface = getUserInterface();

  return ({
    doctorApp: appInterface === 'doctor',
    appDoctor: appInterface === 'doctor',
    patientApp: appInterface === 'patient',
    appPatient: appInterface === 'patient',
  });
};
