import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';

import { useMutation } from '@apollo/react-hooks';
import { Box, CircularProgress, Grid, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  BurstModeTwoTone,
  CheckCircleTwoTone,
  DeleteSweepTwoTone,
  ReportProblem,
} from '@material-ui/icons';

import { DATE_FORMAT, GetFormat, PATIENT_NAME_CAPITALIZED } from '../../../utils/functions';
import { CLEAR_STUDY_FROM_IMPORT_QUEUE } from '../../../queries/RemotePacs/RemotePacs';
import { IconButtonUI } from '../../../componentsUI/IconButton';

const useStyles = makeStyles(() => ({
  list: {
    '&. success': {
      color: 'green',
    },
  },
  tooltip: {
    marginRight: -6,
    '& .success > svg': {
      color: 'green',
    },
  },
  label: {
    fontSize: '1.125rem',
  },
  inlineButton: {
    '& .MuiBox-root': {
      marginBottom: -2,
      '&.completed': {
        marginTop: -2,
      },
      '&.processing': {
        marginTop: 2,
        marginLeft: 3,
        marginRight: -4,
      },
    },
    '& .MuiIconButton-root': {
      width: 10,
      marginTop: -1,
      padding: 0,
    },
    '& .MuiSvgIcon-colorAction': {
      color: '#20A040',
    },
  },
}));

export const ImportStudyWidgetList = ({ studies, instances, loading, onClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [cleared] = useState([]);
  const [blocked] = useState([]);

  const progress = (count, total) => (count / total).toFixed(2) * 100;
  const studyProgress = (study) => progress(study.uploadedInstances, study.studyInstances);
  const progressSize = 17;
  const progressThickness = 12;

  const [clearStudyMutation] = useMutation(
    CLEAR_STUDY_FROM_IMPORT_QUEUE, {
      onCompleted({ clearStudyFromImportQueue: uuid }) {
        if (!cleared.includes(uuid)) cleared.push(uuid);
      },
      onError: () => {
        blocked.splice(0, blocked.length);
      },
    },
  );

  const openStudy = (uuid) => {
    navigate(`/study/${uuid}`);
    onClose();
  };

  const clearStudyFromList = async ({ uuid }) => {
    blocked.push(uuid);
    await clearStudyMutation({ variables: { uuid } });
    blocked.splice(0, blocked.length);
  };

  const isStudyCompleted = (study) => study && study.uploadedInstances >= study.studyInstances;

  const progressLabel = (study) => {
    const uploaded = study && study.uploadedInstances;
    const total = study && study.studyInstances;
    const errors = study && study.errorInstances && total > uploaded;
    if (errors) return t('an.error.occurred.importing.the.study', { uploaded, total });
    if (uploaded === total) return t('study.imported.successfully');
    return t('study.instances.of.total.imported', { uploaded, total });
  };

  const Label = ({ study }) => (
    <Typography className={classes.label}>
      {progressLabel(study)}
    </Typography>
  );

  const StudyPatient = ({ patient }) => GetFormat(patient, PATIENT_NAME_CAPITALIZED);

  const Status = ({ study }) => {
    if (!study) {
      return (
        <CircularProgress
          color="inherit"
          size={progressSize}
          thickness={progressThickness}
        />
      );
    }
    if (study.errorInstances && study.studyInstances > study.uploadedInstances) {
      return <ReportProblem style={{ fontSize: 24, color: 'orange', margin: '-2px -1px -4px -2px' }} />;
    }
    if (!study.uploadedInstances) {
      return (
        <CircularProgress
          color="secondary"
          size={progressSize}
          thickness={progressThickness}
        />
      );
    }
    if (study.uploadedInstances >= study.studyInstances) {
      return <CheckCircleTwoTone color="action" />;
    }
    return (
      <CircularProgress
        variant={loading ? 'indeterminate' : 'determinate'}
        size={progressSize}
        thickness={progressThickness}
        value={studyProgress(study)}
      />
    );
  };

  const StudyDate = ({ study }) => (
    study.createdAt ? GetFormat(study.createdAt, DATE_FORMAT) : ''
  );

  const instanceValues = (study) => {
    const instance = instances && instances.find((inst) => inst.i === study.studyInstanceUid);

    return instance ? {
      uploadedInstances: instance.v && instance.v[0],
      errorInstances: instance.v && instance.v[1],
      studyInstances: instance.v && instance.v[2],
    } : {
      uploadedInstances: study.uploadedInstances,
      errorInstances: study.errorInstances,
      studyInstances: study.studyInstances,
    };
  };

  const mappedStudies = studies.map((study) => ({
    uuid: study.uuid,
    dicomStudyUuid: study.dicomStudyUuid,
    requestUuid: study.requestUuid,
    studyDescription: study.studyDescription,
    studyInstanceUid: study.studyInstanceUid,
    patient: study.patient,
    ...instanceValues(study),
    createdAt: study.createdAt,
    updatedAt: study.updatedAt,
  })).filter((study) => !cleared.includes(study.uuid));

  return (
    <>

      <Grid container spacing={2} className="header">
        <Grid item xs={4}>
          {t('description')}
        </Grid>
        <Grid item xs={4}>
          {t('patient')}
        </Grid>
        <Grid item xs />
        <Grid item />
        <Grid item>
          {t('date')}
        </Grid>
      </Grid>

      {mappedStudies.map((study, index) => (
        <Grid container spacing={2} key={index.toString()} className={classes.list}>
          <Grid item xs={4}>
            <Box className="ellipsis">
              {study.studyDescription}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="ellipsis">
              <StudyPatient patient={study.patient} />
            </Box>
          </Grid>
          <Grid item xs />
          {isStudyCompleted(study) && (
            <Grid item className={classes.inlineButton}>
              <IconButtonUI
                Icon={DeleteSweepTwoTone}
                disabled={blocked.includes(study.uuid)}
                title={t('clear.from.list')}
                handleClick={() => clearStudyFromList(study)}
              />
            </Grid>
          )}
          <Grid item className={classes.inlineButton}>
            <IconButtonUI
              Icon={BurstModeTwoTone}
              title={t('view.study.info')}
              handleClick={() => openStudy(study.dicomStudyUuid)}
            />
          </Grid>
          <Grid item className={classes.inlineButton}>
            <Tooltip title={<Label study={study} />} placement="left" arrow className={classes.tooltip}>
              <Box className={isStudyCompleted(study) ? 'completed' : 'processing'}>
                <Status study={study} />
              </Box>
            </Tooltip>
          </Grid>
          <Grid item>
            <StudyDate study={study} />
          </Grid>
        </Grid>
      ))}
    </>
  );
};
