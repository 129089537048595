import React from 'react';
import DOMPurify from 'dompurify';

export const InnerHTML = ({ content, style = null }) => (
  // eslint-disable-next-line react/no-danger
  <p style={style} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
);

export const InnerDivHTML = ({ content, style = null, classes = null }) => (
  // eslint-disable-next-line react/no-danger
  <div style={style} className={classes} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
);

export const InnerSpanHTML = ({ content, style = null }) => (
  // eslint-disable-next-line react/no-danger
  <span style={style} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
);
