import styled from 'styled-components';
import { sizes } from '../../../styles/variables';

export const EmptyPage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  overflow-y: scroll;

  @media (max-width: ${sizes.smMax}) {
    padding-top: 7%;
    padding-bottom: 7%;
    padding-left: 10%;
    padding-right: 10%;
  }
  
  @media (min-width: ${sizes.mdMin}) and (max-width: ${sizes.mdLgMin}) {
    padding-top: 7%;
    padding-bottom: 7%;
    padding-left: 20%;
    padding-right: 20%;
  }

  @media (min-width: ${sizes.mdLgMin}) {
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: ${(props) => (props.big ? '15%' : '25%')};
    padding-right: ${(props) => (props.big ? '15%' : '25%')};
  }
`;

export const EmptyPageModern = styled.div`
  width: 100%;
  max-width: 100% !important;
  display: flex;
  flex-direction: column;
  align-content: center;
  overflow-y: scroll;

  @media (max-width: ${sizes.smMax}) {
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 1em;
    padding-right: 1em;
  }
  
  @media (min-width: ${sizes.mdMin}) and (max-width: ${sizes.mdLgMin}) {
    padding-top: 1em;
    padding-bottom: 1.5em;
    padding-left: 2em;
    padding-right: 2em;
  }

  @media (min-width: ${sizes.mdLgMin}) {
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: ${(props) => (props.big ? '2em' : '3em')};
    padding-right: ${(props) => (props.big ? '2em' : '3em')};
  }
`;

export const EmptyPageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
