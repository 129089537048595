export const protocolQuestionTypeList = [
  { id: 1, value: 'TEXT', label: 'text' },
  { id: 2, value: 'OPTIONS', label: 'score' },
  { id: 3, value: 'PICTURE', label: 'media' },
];

export const protocolStateList = [
  { id: 1, value: 1, label: 'protocol.private' },
  { id: 2, value: 2, label: 'protocol.hospital.scope' },
  // { id: 3, value: 3, label: 'protocol.public' },
];

export const getProtocolStateLabel = (value) => {
  if (!value) return 'protocol.public';
  const index = protocolStateList.find((state) => state.id === value);
  if (!index) return 'protocol.public';
  return index.label;
};
