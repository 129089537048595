import styled from 'styled-components';
import { sizes } from '../../../styles/variables';

export const SortWrapper = styled.div`
  min-width: 17%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;

  select {
    flex-grow: 1;
    border: none;
  }

  @media (max-width: ${sizes.xsMax}) {
    .sort-label__span {
      display: none;
    }

    select {
      text-align-last: right;
      padding: 0;
    }
  }

  @media (min-width: ${sizes.smMin}) and (max-width: ${sizes.smMax}) {
    min-width: 25%;
  }
`;
