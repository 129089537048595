import React, { useContext } from 'react';

import styled, { ThemeContext } from 'styled-components';
import PulseLoader from 'react-spinners/PulseLoader';

export default ({ message }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <LoadingContainer>
      <>
        {message && (
          <p>{message}</p>
        )}
        <PulseLoader
          className="pulse-loader"
          size={10}
          color={themeContext.colors.primary}
          loading
        />
      </>
    </LoadingContainer>
  );
};

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
`;
