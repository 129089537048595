export const sizes = {
  xsMin: '480px',
  xsMax: '767px',
  smMin: '768px',
  smMax: '991px',
  mdMin: '992px',
  mdMax: '1099px',
  mdLgMin: '1100px',
  lgMin: '1200px',
};
