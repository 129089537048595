import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  button: {
    minWidth: 160,
    float: 'right',
    fontWeight: '700',
    lineHeight: 1.9,
  },
  root: {
    '& .MuiButton-containedSizeLarge': {
      padding: '.25em 1.25em',
    },
  },
}));

export const ButtonUI = (props) => {
  const classes = useStyles();
  const { type, size, color, variant, className = '' } = props;
  const { disabled = false, onClick = () => {}, icon, iconPosition = 'end' } = props;
  const { children } = props;
  const startIcon = iconPosition === 'start' && icon;
  const endIcon = iconPosition === 'end' && icon;

  return (
    <Button
      type={type}
      size={size}
      color={color}
      className={`${classes.button} ${className} MuiPaper-elevation1`}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {children}
    </Button>
  );
};

export default ButtonUI;
