import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import { ArrowBack } from '@material-ui/icons';

import { IconButtonUI } from '../../componentsUI/IconButton';
import { useApplicationInterface } from '../../utils/ApplicationInterfaceUtils';

export default () => {
  const { t } = useTranslation();
  const workspacePage = useSelector((state) => state.userInterface.workspacePage);
  const { patientApp } = useApplicationInterface();
  const backButtonTooltip = patientApp ? 'back.to.dashboard' : 'back.to.hospital';

  return (
    <IconButtonUI
      title={t(backButtonTooltip)}
      Icon={ArrowBack}
      handleClick={() => navigate(workspacePage || '/dashboard')}
    />
  );
};

export const CancelButton = () => {
  const { t } = useTranslation();

  return (
    <IconButtonUI
      title={t('back')}
      Icon={ArrowBack}
      handleClick={() => window.history.back()}
    />
  );
};
