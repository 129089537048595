import styled from 'styled-components';
import { sizes } from '../../../styles/variables';

export const MainContainer = styled.div`
  height: 100%;
  margin-top: 0;
  position: relative;
  display: flex;
  flex-direction: column;

  @media (max-width: ${sizes.xsMax}) {
    margin-top: 0px;
  }

  @media (min-width: ${sizes.smMin}) and (max-width: ${sizes.mdMax}) {
    margin-top: 0px;
  }
`;
