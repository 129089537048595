import React from 'react';
import { useTranslation } from 'react-i18next';

import Drawer from '@material-ui/core/Drawer';
import { Box, Divider, Fab, Tooltip, Zoom } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Flip } from '@material-ui/icons';
import { useMinWidth } from '../../hooks/useResize';
import { sizes } from '../../styles/variables';
import { LogoWrapper } from '../Header/styled/LogoWrapper';
import useStyles from './styled/SidebarStyles';
import Logo from '../Header/Logo';
import WorkspaceName from './WorkspaceName';
import HospitalSwitcher, { PatientSwitcher } from './Switcher';
import Menu from './Menu';
import { getUserInterface } from '../../utils/ApplicationInterfaceUtils';

import {
  hospitalMenuOptions,
  providerMenuOptions,
  hospitalSettingsMenuOptions,
  userSettingsMenuOptions,
  userPatientSettingsMenuOptions,
  patientMenuOptions,
} from './MenuOptions';

export default ({
  type,
  handleDrawerToggle,
  handleSwitch,
  hover,
  setHover,
  visibleSidebar,
  fixSidebar,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const isMinWidth = useMinWidth(sizes.mdMin);
  const ui = getUserInterface();

  if (type === 'new') return null;

  const getMenuOptions = () => {
    const option = `${ui}|${type}`;
    switch (option) {
      case 'doctor|provider':
        return providerMenuOptions;
      case 'doctor|settings':
        return hospitalSettingsMenuOptions;
      case 'doctor|userSettings':
        return userSettingsMenuOptions;
      case 'patient|userSettings':
        return userPatientSettingsMenuOptions;
      case 'patient|undefined':
        return patientMenuOptions;
      default:
        return hospitalMenuOptions;
    }
  };
  const Switcher = ui === 'patient' ? PatientSwitcher : HospitalSwitcher;

  const DrawerContent = () => (
    <>
      <div className={classes.separator} />
      {type === 'userSettings' ? (
        <div className={classes.userData}>
          <span>{t('user.data')}</span>
        </div>
      ) : (
        <>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          {type === 'settings'
            ? <WorkspaceName type={type} py-6 px-9 pb-0 />
            : <Switcher type={type} />}
        </>
      )}
      <Divider color="primary" />
      <Menu fixSidebar={fixSidebar} options={getMenuOptions()} />
    </>
  );

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const fixSidebarLabel = fixSidebar ? t('unpin.sidebar') : t('pin.sidebar');
  const navClass = (fixSidebar && !isMinWidth) ? classes.drawer : classes.noDrawer;

  return (
    <Box
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <nav className={navClass}>
        <Drawer
          variant={(fixSidebar && !isMinWidth) ? 'permanent' : 'temporary'}
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={visibleSidebar}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <DrawerContent />
        </Drawer>
      </nav>
      {!isMinWidth && (
        <Box
          className={classes.fab}
        >
          <Zoom
            in={hover}
            timeout={transitionDuration}
            style={{ transitionDelay: '0ms' }}
            unmountOnExit
          >
            <Tooltip aria-label="fix-sidebar-tooltip" title={fixSidebarLabel}>
              <Fab aria-label="fix-sidebar" color="primary" onClick={handleSwitch}>
                <Flip />
              </Fab>
            </Tooltip>
          </Zoom>
        </Box>
      )}
    </Box>
  );
};
