export const parseGraphqlErrors = (errors, translate = (content) => content) => {
  const formikErrors = {};

  // eslint-disable-next-line array-callback-return
  errors.map((error) => {
    const { code } = error;
    if (code === undefined) return;

    const keys = Object.keys(code);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      if (formikErrors[key] === undefined) {
        formikErrors[key] = translate(code[key][0]);
      }
    }
  });

  return formikErrors;
};
