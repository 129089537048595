import React, { useRef, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from 'react-apollo';
import { useDispatch } from 'react-redux';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';

import NotificationIcon from '@material-ui/icons/NotificationsOutlined';
import { withStyles } from '@material-ui/core/styles';
import {Box, Divider, Menu, MenuItem} from '@material-ui/core';
import { AddCircle, Announcement, Info, Sms } from '@material-ui/icons';
import { GET_NOTIFICATIONS } from '../../../graphql/queries';
import { IconButtonUI } from '../../../componentsUI/IconButton';
import { FromNow } from '../../Common/FormattedDate';
import { Row } from '../../Common/styled/Groups';
import { READ_NOTIFICATION } from '../../../graphql/mutations';
import { ParseNotificationMessage } from '../../../utils/NotificationUtils';
import { InnerSpanHTML } from '../../Common/InnerHTML';

export const Notifications = () => {
  const { t } = useTranslation();
  const r = useRef();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [unreadNotifications, setUnreadNotifications] = useState(null);
  const { data } = useQuery(
    GET_NOTIFICATIONS,
    {
      variables: { first: 15 },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      onCompleted: (d) => setUnreadNotifications(d.notifications.totalUnreadNotifications),
      onError: (e) => {
        const error = e.graphQLErrors[0];
        if (error && error.message === 'License not found') {
          dispatch({ type: 'SET_LICENSE', licenseWarning: { licenseWarning: true } });
        }
      },
    },
  );

  const [readNotification] = useMutation(READ_NOTIFICATION);
  const NotificationMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
      // '&::-webkit-scrollbar': { display: 'none' },
    },
  })((props) => (
    <Menu
      ref={r}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ));

  const NotificationMenuItem = withStyles((theme) => ({
    root: {
      fontSize: '.9375em',
      color: '#707070',
      paddingRight: '2em',
      whiteSpace: 'normal',
      '&:hover': {
        color: theme.palette.grey.A400,
      },
      '& p': {
        lineHeight: '1.2',
      },
      '& .notification-date': {
        color: theme.palette.grey.A700,
        fontSize: '.875em',
        fontWeight: '600',
      },
      '& .notification-icon': {
        color: theme.palette.grey.A200,
        fontSize: '1.25em',
        marginTop: '.125em',
        paddingRight: '.375em',
      },
      '&.notification-info': {
        cursor: 'default',
        '&:hover': {
          backgroundColor: theme.palette.common.white,
        },
      },
      '&.notification-load': {
        '& svg': {
          fontSize: '1.875em',
          paddingRight: '.25em',
        },
        '&:hover': {
          backgroundColor: theme.palette.common.white,
        },
      },
      '& .notification-info-icon': {
        color: theme.palette.primary.main,
        fontSize: '1.5em',
        marginTop: '.1875em',
        paddingRight: '.5em',
      },
      '& .notification-not-read-icon': {
        color: theme.palette.primary.light,
      },
      '& .notification-not-read-text': {
        color: theme.palette.primary.dark,
      },
      '&.notificationHeader': {
        fontSize: '1.125em',
        fontWeight: '700',
        color: theme.palette.grey.A400,
        paddingBottom: '1rem',
        cursor: 'default',
        '&:hover': {
          color: theme.palette.grey.A400,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

  const NOTIFICATIONS_LIST_MENU_HEIGHT = 48;
  const isNotificationsPage = window.location.pathname === '/notifications';

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleReadNotification = (node) => {
    handleClose();
    readNotification({ variables: { uuid: node.uuid } })
      .then(({ data: dataUpdate }) => {
        if (dataUpdate.readNotification.hospital) {
          dispatch({ type: 'CHANGE_HOSPITAL', current: dataUpdate.readNotification.hospital });
        }
        // window.location.assign(node.link);
        navigate(node.link);
      })
      .catch(() => {
        navigate(node.link);
      });
  };
  const handleLoadMoreNotifications = () => navigate('/notifications');

  if (!data || !data.notifications) return null;

  const NotificationsEmpty = () => (
    <NotificationMenuItem className="notification-info">
      <Info className="notification-info-icon" />
      <p className="mt-2 mb-0">{t('no.notifications')}</p>
    </NotificationMenuItem>
  );

  const NotificationList = ({ edges }) => edges.map(({ node }) => (
    <NotificationMenuItem
      onClick={() => handleReadNotification(node)}
      key={node.uuid}
    >
      <div>
        <Row>
          {!node.readAt ? (
            <Announcement className="notification-icon notification-not-read-icon" />
          ) : (
            <Sms className="notification-icon" />
          )}
          <p className={node.readAt ? 'my-0' : 'my-0 notification-not-read-text'}>
            <InnerSpanHTML content={ParseNotificationMessage(node.message)} />
          </p>
        </Row>
        <Row>
          <p className="my-1 notification-date">
            <FromNow date={node.createdAt} />
          </p>
        </Row>
      </div>
    </NotificationMenuItem>
  ));

  const LoadMoreNotifications = () => (
    <NotificationMenuItem className="notification-load" onClick={handleLoadMoreNotifications}>
      <AddCircle className="notification-info-icon ml-auto" />
      <p className="mt-2 mb-0">{t('see.more.notifications')}</p>
    </NotificationMenuItem>
  );

  return (
    <Box style={{ margin: '0 8px' }}>
      <IconButtonUI
        Icon={NotificationIcon}
        title={t('notifications')}
        handleClick={handleClick}
        badgeContent={unreadNotifications}
        disabled={!!anchorEl || isNotificationsPage}
        size="1.1875em"
      >
        <NotificationMenu
          id="notification-list"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '400px',
              maxHeight: NOTIFICATIONS_LIST_MENU_HEIGHT * 10,
              maxWidth: 'calc(100% - 90px)',
            },
          }}
        >
          <NotificationMenuItem className="notificationHeader">{t('notifications')}</NotificationMenuItem>
          <Divider />
          {data && data.notifications && (
            !data.notifications.edges.length
              ? <NotificationsEmpty />
              : <NotificationList edges={data.notifications.edges} />
          )}
          {data.notifications.pageInfo.hasNextPage && <LoadMoreNotifications />}
        </NotificationMenu>
      </IconButtonUI>
    </Box>
  );
};
