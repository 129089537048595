
import styled from 'styled-components';
import { sizes } from '../../../../styles/variables';

export const UserAccountWrapper = styled.div`
  height: 100%;
  margin-left: 8px;
  object-fit: contain;
  // cursor: pointer;
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;

  @media (max-width: ${sizes.xsMax}) {
    display: flex;
    align-items: center;
  }

  @media (min-width: ${sizes.smMin}) {
    padding: 0 15px 0 15px;

    display: flex;
    flex-direction: row;
    align-items: center;
    border-left: solid 1px ${(props) => props.theme.colors.gray200};
    box-sizing: border-box;
  }
`;

export const UserAccountIcon = styled.img`
  width: 63px;
  height: 63px;
  // margin-right: 14px;

  @media (max-width: ${sizes.xsMax}) {
    width: 50px;
    height: 50px;
    margin-right: 0;
  }
`;

export const UserAccountLabel = styled.div`
  // min-width: 10vw;
  padding-right: 10px;

  @media (max-width: 599px) {
    display: none;
  }
`;
