import React from 'react';

import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  large: {
    // margin: theme.spacing(1),
    cursor: 'pointer',
    width: theme.spacing(6),
    height: theme.spacing(6),
    border: '1px grey solid',
  },
  initials: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    fontSize: '.9375em',
    cursor: 'pointer',
  },
}));

export default ({ user }) => {
  const classes = useStyles();

  const initials = user && (user.name.charAt(0) + user.surname.charAt(0)).toUpperCase();

  if (user && user.profilePhoto) {
    return (
      <Avatar alt="User" className={classes.large} src={user.profilePhoto} />
    );
  }

  return (
    <Avatar className={classes.initials} variant="circular">
      {initials.toUpperCase()}
    </Avatar>
  );
};
