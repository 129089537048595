import React, { useState, useEffect } from 'react';
import { useQueryParam } from 'gatsby-query-params';
import { navigate } from 'gatsby';

import { MainSearchTextInputWrapper } from './styled/MainSearchTextInputWrapper';
import SearchInput from '../../Search/SearchInput';

export default () => {
  const [q, setQ] = useState('');
  const queryParam = useQueryParam('q');

  useEffect(() => {
    if (queryParam) setQ(queryParam);
  }, [queryParam]);

  function onSubmit(event) {
    event.preventDefault();
    navigate(`/search?q=${q}`);
  }

  return (
    <MainSearchTextInputWrapper>
      <form onSubmit={onSubmit}>
        <SearchInput
          name="q"
          value={q}
          onChange={(e) => setQ(e.target.value)}
        />
      </form>
    </MainSearchTextInputWrapper>
  );
};
