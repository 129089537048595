
export default {
  information: {
    name: 'information',
  },
  hamburger_menu: {
    name: 'hamburger_menu',
  },
  notifications_bell: {
    name: 'notifications_bell',
  },
  add: {
    name: 'add',
  },
  agenda: {
    name: 'agenda',
  },
  arrow_down: {
    name: 'arrow_down',
  },
  arrow_left: {
    name: 'arrow_left',
  },
  arrow_right: {
    name: 'arrow_right',
  },
  arrow_up: {
    name: 'arrow_up',
  },
  cases_alt: {
    name: 'cases_alt',
  },
  cases: {
    name: 'cases',
  },
  close: {
    name: 'close',
  },
  'dashboard-pie': {
    name: 'dashboard-pie',
  },
  dashboard: {
    name: 'dashboard',
  },
  download: {
    name: 'download',
  },
  edit: {
    name: 'edit',
  },
  file_DICOM: {
    name: 'file_DICOM',
  },
  file_IMG: {
    name: 'file_IMG',
  },
  file_PDF: {
    name: 'file_PDF',
  },
  file_STL: {
    name: 'file_STL',
  },
  group: {
    name: 'group',
  },
  patient: {
    name: 'patient',
  },
  search: {
    name: 'search',
  },
  settings: {
    name: 'settings',
  },
  share: {
    name: 'share',
  },
  workspace_switcher: {
    name: 'workspace_switcher',
  },
};
