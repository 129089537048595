import { useTranslation } from 'react-i18next';

export const ParseNotificationMessage = (text) => {
  const { t } = useTranslation();
  try {
    const json = JSON.parse(text);
    return `${t(json.template, json.options)}`;
  } catch (e) {
    return text;
  }
};
