import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Divider, Toolbar, Typography } from '@material-ui/core';

import { IconButtonUI } from './IconButton';
import { cannotI } from '../casl/Can';
import ApplicationPath from '../components/Navbar/ApplicationPath';
import { useMinWidth } from '../hooks/useResize';
import { sizes } from '../styles/variables';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    margin: '0 -24px',
    padding: '0 32px',
    backgroundColor: 'transparent',
    maxWidth: 'calc(100vw - 336px)',
    zIndex: 5,
  },
  longToolbar: {
    margin: '0 -24px',
    padding: '0 32px',
    backgroundColor: 'transparent',
    maxWidth: 'calc(100vw - 4em)',
    zIndex: 5,
    '@media (max-width:600px)': {
      maxWidth: 'calc(100vw - 1.125em)',
      padding: '0 4px 0 16px',
    },
  },
  mobileToolbar: {
    margin: '0 -24px',
    padding: '0 40px',
    backgroundColor: 'transparent',
    minHeight: 20,
    '@media (min-width:601px)': {
      display: 'none',
    },
  },
  breadcrumbs: {
    margin: '0 -24px',
    padding: '0 16px',
    backgroundColor: '#efefef',
    maxWidth: 'calc(100% + 1.125em)',
    minHeight: 20,
    overflow: 'hidden',
    zIndex: 4,
  },
  title: {
    flexGrow: 1,
    fontWeight: 700,
    whiteSpace: 'nowrap',
    fontSize: '1.7rem',
    '@media (max-width:600px)': {
      visibility: 'hidden',
      width: 5,
    },
  },
  subtitle: {
    fontSize: '.875em',
    color: theme.palette.primary.main,
    lineHeight: 1.25,
    fontWeight: 700,
  },
  mobileTitle: {
    flexGrow: 1,
    fontWeight: 700,
    whiteSpace: 'nowrap',
    fontSize: '1.7rem',
    marginLeft: '-1.5em',
    marginTop: '-1.125em',
  },
  mobileSubtitle: {
    color: theme.palette.primary.main,
    lineHeight: 1.25,
    fontWeight: 700,
    marginTop: '-0.875em',
    paddingRight: '1em',
    position: 'absolute',
    right: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  divider: {
    height: 18,
    margin: '0 2px',
    '@media (max-width:600px)': {
      display: 'none',
    },
  },
  leftIconWrapper: {
    marginRight: '2em',
  },
}));

const ButtonType = ({ item, classes, t }) => {
  let disabled = item && item.I && item.data ? cannotI(item.I, item.data) : false;
  if (!disabled && item.disabled !== undefined) disabled = item.disabled;

  switch (item.type) {
    case 'divider':
      return (
        <Divider key={item.name} className={classes.divider} orientation="vertical" />
      );
    default:
      return (
        <IconButtonUI
          key={item.name}
          Icon={item.icon}
          title={t(item.name)}
          disabled={disabled}
          selected={item.selected}
          handleClick={item.handleClick}
        />
      );
  }
};

const SectionBarLeftIconWrapper = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.leftIconWrapper}>
      {children}
    </div>
  );
};

export const SectionBar = ({ title, subtitle, items, back, breadcrumbs }) => {
  const { fixSidebar } = useSelector((state) => state.userInterface);
  const { t } = useTranslation();
  const classes = useStyles();
  const isMinWidth = useMinWidth(sizes.mdMin);
  const props = { t, classes };

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar className={(fixSidebar && !isMinWidth) ? classes.toolbar : classes.longToolbar}>
        { !!back && (
          <SectionBarLeftIconWrapper>
            <IconButtonUI
              Icon={back.icon}
              title={back.label}
              handleClick={back.onClick}
            />
          </SectionBarLeftIconWrapper>
        )}
        <Typography variant="h6" className={classes.title}>
          {t(title)}
          {subtitle
            ? <div className={classes.subtitle}>{subtitle}</div>
            : null}
        </Typography>
        {!!items && items
          .filter((item) => !item.hidden)
          .map((item, index) => <ButtonType key={index.toString()} item={item} {...props} />)}
      </Toolbar>
      <Toolbar className={classes.mobileToolbar}>
        <Typography variant="h6" className={classes.mobileTitle}>
          {t(title)}
        </Typography>
        {subtitle
          ? <Typography variant="subtitle1" className={classes.mobileSubtitle}>{subtitle}</Typography>
          : null}
      </Toolbar>
      {!!breadcrumbs && (
        <Toolbar className={classes.breadcrumbs}>
          <div>
            <ApplicationPath
              patient={breadcrumbs.patient}
              medicalCase={breadcrumbs.medicalCase}
              study={breadcrumbs.study}
              advancedTool={breadcrumbs.advancedTool}
            />
          </div>
        </Toolbar>
      )}
    </AppBar>
  );
};

export default SectionBar;
