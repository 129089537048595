import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { useKeycloak } from 'react-keycloak';
import { useTranslation } from 'react-i18next';

import '../i18n';
import '../styles/global.css';
import '../../node_modules/bootstrap-utilities/bootstrap-utilities.css';

import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';

import Seo from './Seo';
import Viewer from './Viewer/Viewer';
import { AppContainer } from './Common/styled/AppContainer';
import GlobalStyle from '../themes/GlobalStyle';
import GlobalThemeStyles from '../themes/GlobalThemeStyles';
import { ToastContainer } from './Common/Toast/ToastContainer';
import Loading from './Common/Loading';
import { isEmpty } from '../utils/ObjectUtils';
import { StudyUploadWidget } from './Widget/Upload/StudyUploadWidget';

import { GET_PATIENT_HOSPITALS, GET_USER_PATIENTS } from '../graphql/queries';
import { getSessionStorageJson } from '../utils/ReadStorageUtils';
import { getCurrentPatientUserHospital } from '../utils/HospitalUtils';
import { AlertContainerUI, AlertUI } from '../componentsUI/Alert';
import { EmptyPageModern } from './Common/styled/EmptyPage';
import { StudyDownloadWidget } from './Widget/Download/StudyDownloadWidget';

export default ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userInterface.user);
  const { loading: loadingPatients, data: queryPatientsData } = useQuery(GET_USER_PATIENTS, {
    onCompleted: (d) => {
      sessionStorage.setItem('patientsData', JSON.stringify(d));
    },
  });
  const { data: queryHospitalData } = useQuery(GET_PATIENT_HOSPITALS, {
    onCompleted: (d) => {
      sessionStorage.setItem('hospitalData', JSON.stringify(d));
    },
  });
  const patientsData = !isEmpty(queryPatientsData) ? queryPatientsData : getSessionStorageJson('patientsData');
  const hospitalData = !isEmpty(queryHospitalData) ? queryHospitalData : getSessionStorageJson('hospitalData');
  const currentHospital = useSelector((state) => state.hospital);
  const themeContext = useContext(ThemeContext);
  const [keycloak] = useKeycloak();

  if (!keycloak.tokenParsed.roles.includes('https://api.cloud.alma-medical.com/patient')) {
    if (keycloak.tokenParsed.roles.includes('https://api.cloud.alma-medical.com/doctor')) {
      window.location.href = process.env.GATSBY_APP_CLOUD_URL;
    }

    return <div>You don&apos;t have the permission to access the platform</div>;
  }

  if (loadingPatients) {
    return <Loading />;
  }

  const userHospital = getCurrentPatientUserHospital(patientsData, hospitalData, user);

  if (!userHospital) {
    return (
      <AppContainer>
        <EmptyPageModern>
          <AlertContainerUI>
            <AlertUI severity="warning" title={t('access.forbidden')}>{t('you.do.not.have.access.to.any.existing.hospital')}</AlertUI>
          </AlertContainerUI>
        </EmptyPageModern>
      </AppContainer>
    );
  }

  if (userHospital && !currentHospital) {
    dispatch({ type: 'CHANGE_HOSPITAL', current: userHospital });
  }

  if (currentHospital && currentHospital.mainColor) {
    themeContext.setPrimaryColor(currentHospital.mainColor);
  }

  return (
    <AppContainer>
      <Seo />
      <GlobalStyle />
      <GlobalThemeStyles />
      {children}
      <ToastContainer />
      <StudyUploadWidget />
      <StudyDownloadWidget />
      <Viewer />
    </AppContainer>
  );
};
