/*
Queries for:
-----
Case
Groups
Hospitals
Notifications
Patients
Studies
Teams
Users
*/
import gql from 'graphql-tag';

// Case
export const GET_CASES = gql`
  query MedicalCases($hospitalUuid: ID!, $first: Int, $cursor: String, $orderBy: MedicalCaseOrder) {
    medicalCases(hospitalUuid: $hospitalUuid, first: $first, after: $cursor, orderBy: $orderBy) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          uuid
          title
          createdBy
          createdAt
          active
          patient {
            uuid
            name
            surname
          }
          studies {
            uuid
            title
            createdAt
            numberOfSeries
            numberOfInstances
            modalities
          }
          medicalCaseFiles {
            uuid
            file
            fileName
            fileSize
          },
        }
      }
    }
  }
`;

export const GET_PATIENT_MEDICAL_CASES = gql`
  query ($uuid: ID!) {
    patient(uuid: $uuid) {
      uuid
      customId
      name
      surname
      birthDate
      createdAt
      createdBy
      medicalCases {
        uuid
        title
        active
        createdAt
        studies {
          uuid
          studyInstanceUid
          title
          createdAt
          numberOfSeries
          numberOfInstances
          modalities
        }
      }
    }
  }
`;

export const GET_MEDICAL_CASE_FOR_EDITING = gql`
  query ($uuid: ID!) {
    medicalCase(uuid: $uuid) {
      uuid
      title
      description
      specialty
      subspecialty
      createdBy
      createdAt
      active
      patient {
        uuid
        name
        surname
        birthDate
        email
        userUuid
        hospital {
          uuid
        }
      }
      studies {
        uuid
        title
        createdAt
        numberOfSeries
        numberOfInstances
        modalities
      },
      virtualVisits {
        uuid
        appointment
        answered
        doctor {
          uuid
          name
          surname
        }
        patient {
          uuid
          name
          surname
        }
      },
      medicalCaseFiles {
        uuid
        file
        fileName
        fileSize
      },
      collaborations {
        uuid
        permission
        userEmail
        user {
          uuid
          name
          surname
          email
          profilePhoto
        }
      }
      groupCollaborations {
        uuid
        permission
        group {
          uuid
          name
        }
      }
      comments {
        uuid
        body
        createdAt
        patientCommunication
        author {
          uuid
          name
          surname
          profilePhoto
        }
      }
    }
  }
`;

export const GET_MEDICAL_CASE_FOR_PREVIEW = gql`
  query ($uuid: ID!) {
    medicalCase(uuid: $uuid) {
      uuid
      title
      description
      specialty
      subspecialty
      active
      patient {
        name
        surname
      }
    }
  }
`;

export const GET_RECENT_MEDICAL_CASES = gql`
  query MedicalCases($hospitalUuid: ID!, $first: Int, $orderBy: MedicalCaseOrder) {
    medicalCases(hospitalUuid: $hospitalUuid, first: $first, orderBy: $orderBy) {
      edges {
        node {
          uuid
          title
          createdAt
          updatedAt
          specialty
          subspecialty
          patient {
            uuid
            name
            surname
          }
          studies {
            uuid
            title
            createdAt
            numberOfSeries
            numberOfInstances
            modalities
          }
          medicalCaseFiles {
            uuid
            file
            fileName
            fileSize
          }
        }
      }
    }
  }
`;

// Groups
export const GET_HOSPITAL_GROUPS_FOR_PREVIEW = gql`
  query ($uuid: ID!) {
    hospital(uuid: $uuid) {
      uuid
      owner
      teamMembers {
        uuid
        role
        user {
          uuid
        }
      }
      userGroups {
        uuid
        name
        groupMembers {
          uuid
          user {
            uuid
            name
            surname
            email
            profilePhoto
          }
        }
      }
    }
  }
`;

export const GET_HOSPITAL_GROUPS_FOR_EDITING = gql`
  query ($uuid: ID!) {
    hospital(uuid: $uuid) {
      uuid
      name
      institutionName
      owner
      teamMembers {
        uuid
        role
        user {
          uuid
          name
          surname
          email
          profilePhoto
        }
      }
      userGroups {
        uuid
        name
        groupMembers {
          uuid
          user {
            uuid
            name
            surname
            email
            profilePhoto
          }
        }
      }
    }
  }
`;

// Hospitals
export const GET_HOSPITALS = gql`
  query {
    hospitals {
      totalCount
      edges {
        node {
          ... on Hospital {
            __typename
            uuid
            name
            institutionName
            owner
            logo
            mainColor
            menuOptionAIStats
            menuOptionStudies
            menuOptionMedicalCases
            menuOptionPatients
            menuOptionGroups
            menuOptionProtocols
            menuOptionVirtualVisits
            menuOptionFiles
            menuOptionStowRs
            menuOptionRemotePacs
            menuOptionSettings
            enableCdLiteDownload
            createdAt
            teamMembers {
              uuid
              role
              user {
                uuid
                name
                surname
              }
            }
            userGroups {
              uuid
              name
            }
            availableAdvancedTools {
              uuid
            }
          }
        }
      }
    }
  }
`;

// User Patient available Hospitals
export const GET_PATIENT_HOSPITALS = gql`
  query {
    patientHospitals {
      totalCount
      edges {
        node {
          ... on Hospital {
            __typename
            uuid
            name
            logo
            mainColor
            menuOptionStudies
            menuOptionMedicalCases
            menuOptionVirtualVisits
            menuOptionFiles
            createdAt
          }
        }
      }
    }
  }
`;

export const GET_HOSPITAL = gql`
  query($uuid: ID!) {
    hospital(uuid: $uuid) {
      uuid
      name
      institutionName
      streetAddress
      postalCode
      city
      country
      mainColor
      owner
      createdAt
      updatedAt
      logo
      teamMembers {
        uuid
        role
        user {
          uuid
        }
      }
      menuOptionAIStats
      menuOptionStudies
      menuOptionMedicalCases
      menuOptionPatients
      menuOptionGroups
      menuOptionProtocols
      menuOptionVirtualVisits
      menuOptionFiles
      menuOptionStowRs
      menuOptionRemotePacs
      menuOptionSettings
      enableCdLiteDownload
    }
  }
`;

export const GET_HOSPITAL_MEMBERS = gql`
  query($uuid: ID!) {
    hospital(uuid: $uuid) {
      uuid
      name
      owner
      teamMembers {
        uuid
        role
        user {
          name
          surname
        }
      }
      userGroups {
        uuid
        name
      }
    }
  }
`;

export const GET_HOSPITAL_BY_INSTITUTION = gql`
  query($institutionName: string!) {
    hospitalByInstitution(institutionName: $institutionName) {
      uuid
      name
      institutionName
      streetAddress
      postalCode
      city
      country
      mainColor
      owner
      createdAt
      updatedAt
      logo
    }
  }
`;

export const GET_MEMBER_HOSPITAL = gql`
  query($hospitalUuid: ID!) {
    memberHospital(hospitalUuid: $hospitalUuid) {
      uuid
      name
      logo
    }
  }
`;

export const GET_PATIENT_HOSPITAL = gql`
  query($patientUuid: ID!) {
    patientHospital(patientUuid: $patientUuid) {
      uuid
      name
      logo
    }
  }
`;

export const GET_PROVIDERS = gql`
  query {
    providers {
      totalCount
      edges {
        cursor
        node {
          ... on Provider {
            __typename
            uuid
            name
            ownerUuid
            owner {
              uuid
              name
              surname
            }
            logo
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export const GET_HOSPITAL_FILES = gql`
  query($uuid: ID!) {
    hospital(uuid: $uuid) {
      uuid
      name
      institutionName
      owner
      studies {
        uuid
        title
        createdAt
        numberOfSeries
        numberOfInstances
        modalities
      }
      hospitalFiles{
        uuid
        file
        fileName
        fileSize
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_HOSPITAL_FILES_TO_LIST = gql`
  query HospitalFiles(
    $hospitalUuid: ID!,
    $first: Int,
    $cursor: String,
    $orderBy: HospitalFileOrder
  )
  {
    hospitalFiles(
      hospitalUuid: $hospitalUuid,
      first: $first,
      after: $cursor,
      orderBy: $orderBy
    )
    {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          uuid
          file
          fileName
          fileSize
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GET_HOSPITAL_STUDIES_TO_LIST = gql`
  query DicomStudies(
    $hospitalUuid: ID!,
    $patientUuid: ID,
    $first: Int,
    $cursor: String,
    $orderBy: DicomStudyOrder,
    $filters: SearchDicomStudyFilterInput,
    $createdAtAfter: DateTime,
    $createdAtBefore: DateTime,
  )
  {
    dicomStudies(
      hospitalUuid: $hospitalUuid,
      patientUuid: $patientUuid,
      first: $first,
      after: $cursor,
      orderBy: $orderBy,
      filters: $filters,
      createdAtAfter: $createdAtAfter,
      createdAtBefore: $createdAtBefore,
    )
    {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          uuid
          title
          patientId
          patientName
          description
          studyInstanceUid
          createdAt
          numberOfSeries
          numberOfInstances
          modalities
          medicalCase {
            uuid
            title
            patient {
              uuid
              name
              surname
            }
          }
          hospital {
            uuid
            name
          }
          restricted
        }
      }
    }
  }
`;

export const GET_COMMON_HOSPITAL_STUDIES_TO_LIST = gql`
  query HospitalStudies(
    $hospitalUuid: ID!,
    $first: Int,
    $cursor: String,
    $orderBy: DicomStudyOrder
  )
  {
    hospitalStudies(
      hospitalUuid: $hospitalUuid,
      first: $first,
      after: $cursor,
      orderBy: $orderBy
    )
    {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          uuid
          title
          studyInstanceUid
          createdAt
          numberOfSeries
          numberOfInstances
          modalities
        }
      }
    }
  }
`;

export const GET_HOSPITAL_FILE = gql`
  query($uuid: ID!) {
    hospitalUploadedFile(uuid: $uuid){
        uuid
        fileName
        fileSize
        createdAt
        updatedAt
    }
  }
`;

export const GET_STUDIES_MODALITIES = gql`
  query(
    $hospitalUuid: ID,
    $patientUuid: ID,
    $createdAtAfter: DateTime,
    $createdAtBefore: DateTime,
    $filters: SearchDicomStudyFilterInput,
  ) {
    modalities(
      hospitalUuid: $hospitalUuid,
      patientUuid: $patientUuid,
      createdAtAfter: $createdAtAfter,
      createdAtBefore: $createdAtBefore,
      filters: $filters
    )
  }
`;

// Notifications
export const GET_NOTIFICATIONS = gql`
  query ($first: Int, $cursor: String, $read: String) {
    notifications(first: $first, after: $cursor, read: $read) {
      totalCount
      totalUnreadNotifications
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          uuid
          link
          message
          readAt
          createdAt
        }
      }
    }
  }
`;

// Patients
export const GET_PATIENT_RELATED_DATA = gql`
  query ($uuid: ID!) {
    patient(uuid: $uuid) {
      uuid
      customId
      name
      surname
      birthDate
      email
      identityNumber
      nationality
      insurance
      insuranceNumber
      phone
      address
      gender
      createdAt
      createdBy
      createdByData
      invitedBy
      userUuid
      hospital {
        uuid
      }
      medicalCases {
        __typename
        uuid
        title
        createdAt
        active
        studies {
          uuid
          title
          studyInstanceUid
          storageSize
          createdAt
          numberOfSeries
          numberOfInstances
          modalities
        }
        medicalCaseFiles {
          uuid
          file
          fileName
          fileSize
        }
      }
      patientFiles {
        __typename
        uuid
        file
        fileName
        fileSize
      }
      explorations {
        __typename
        uuid
        userUuid
        name
        comment
        weight
        height
        pulse
        respirations
        temperature
        bloodPressureSystolic
        bloodPressureDiastolic
        oxygenSaturation
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PATIENT = gql`
  query ($uuid: ID!) {
    patient(uuid: $uuid) {
      uuid
      customId
      name
      surname
      birthDate
      email
      identityNumber
      nationality
      insurance
      insuranceNumber
      phone
      address
      gender
      createdBy
    }
  }
`;

export const GET_PATIENTS_FOR_PREVIEW = gql`
  query Patients($hospitalUuid: ID!, $cursor: String, $orderBy: PatientOrder) {
    patients(hospitalUuid: $hospitalUuid, first: 10, after: $cursor, orderBy: $orderBy) {
      __typename
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          uuid
          userUuid
          name
          surname
          email
          identityNumber
          nationality
          insurance
          insuranceNumber
          phone
          address
          gender
          customId
          createdAt
          createdBy
          createdByData
          birthDate
          invitedBy
          hospital {
            owner
          }
          medicalCases {
            uuid
            title
            createdAt
            createdBy
            collaborations {
              uuid
              permission
              user {
                uuid
              }
            }
            groupCollaborations {
              uuid
              permission
              group {
                uuid
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_AUTHORIZED_PATIENTS_FOR_PREVIEW = gql`
query Patients($hospitalUuid: ID!, $cursor: String, $orderBy: PatientOrder) {
  authorizedPatients(hospitalUuid: $hospitalUuid, first: 50, after: $cursor, orderBy: $orderBy) {
    __typename
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
      node {
        uuid
        userUuid
        name
        surname
        email
        gender
        customId
        updatedAt
        createdBy
        createdByData
        birthDate
        hospital {
          uuid
          owner
        }
        medicalCases {
          uuid
          title
          createdAt
          createdBy
          collaborations {
            uuid
            permission
            user {
              uuid
            }
          }
          groupCollaborations {
            uuid
            permission
            group {
              uuid
            }
          }
        }
      }
    }
  }
}
`;

export const GET_PATIENTS_ON_NEW = gql`
query Patients($hospitalUuid: ID!, $after: String, $orderBy: PatientOrder) {
  patients(hospitalUuid: $hospitalUuid, after: $after, orderBy: $orderBy) {
    totalCount
    edges {
      cursor
      node {
        uuid
        name
        surname
        birthDate
        customId
        createdAt
        medicalCases {
          uuid
          title
          createdAt
        }
      }
    }
  }
}
`;

export const GET_PATIENTS_ON_NEW_CASE = gql`
  query listPatients($hospitalUuid: ID!, $orderBy: PatientOrder) {
    listPatients(hospitalUuid: $hospitalUuid, orderBy: $orderBy) {
      totalCount
      edges {
        cursor
        node {
          uuid
          name
          surname
          birthDate
          customId
          updatedAt
        }
      }
    }
  }
`;

// Studies
export const GET_STUDY = gql`
  query ($uuid: ID!) {
    dicomStudy(uuid: $uuid) {
      uuid
      title
      owner
      createdAt
      updatedAt
      description
      hospital {
        uuid
        enableCdLiteDownload
        enableDcmRejection
        availableAdvancedTools {
          uuid
          provider {
            uuid
          }
        }
        teamMembers {
          uuid
          role
          user {
            uuid
          }
        }
      }
      medicalCase {
        uuid
        title
        createdBy
        patient {
          uuid
          name
          surname
          birthDate
          customId
          hospital {
            uuid
            enableCdLiteDownload
            enableDcmRejection
            availableAdvancedTools {
              uuid
              provider {
                uuid
              }
            }
          }
        }
        collaborations {
          uuid
          permission
          user {
            uuid
          }
        }
        groupCollaborations {
          uuid
          permission
          group {
            uuid
          }
        }
      }
      studyInstanceUid
      numberOfSeries
      numberOfInstances
      modalities
      storageSize
      restricted
      reports {
        uuid
        status
        createdAt
        advancedTool {
          uuid
          name
          image
          feedbackURL
          provider {
            uuid
            name
            logo
          }
        }
      }
      collaborations {
        uuid
        permission
        user {
          uuid
          name
          surname
          email
          profilePhoto
        }
      }
      groupCollaborations {
        uuid
        permission
        group {
          uuid
          name
        }
      }
    }
  }
`;

// Studies
export const GET_STUDY_BY_INSTANCE_UID = gql`
  query ($studyInstanceUid: String!) {
    dicomStudyByInstanceUid(studyInstanceUid: $studyInstanceUid) {
      uuid
      title
      owner
      createdAt
      updatedAt
      description
      studyInstanceUid
      numberOfSeries
      numberOfInstances
      modalities
    }
  }
`;

// Teams
export const GET_HOSPITAL_TEAM = gql`
  query ($uuid: ID!) {
    hospital(uuid: $uuid) {
      createdAt
      uuid
      owner
      ownerUser {
        uuid
        name
        surname
        email
      }
      teamMembers {
        uuid
        role
        createdAt
        user {
          uuid
          name
          surname
          email
        }
      }
    }
  }
`;

export const GET_TEAM_MEMBERS_AND_GROUPS = gql`
  query GetTeamMembers ($uuid: ID!) {
    hospital(uuid: $uuid) {
      uuid
      owner
      ownerUser {
        uuid
        name
        surname
        email
      }
      teamMembers {
        uuid
        user {
          uuid
          name
          surname
          profilePhoto
        }
        role
      }
      userGroups {
        uuid
        name
        groupMembers {
          uuid
          user {
            uuid
            name
            surname
          }
        }
      }
    }
  }
`;

export const GET_USER_PATIENTS = gql`
  query {
    userPatients {
      uuid
      invitedBy
      hospital {
        uuid
        name
        logo
        mainColor
      }
    }
  }
`;

export const IS_PATIENT_REGISTERED = gql`
  query ($userUuid: ID!, $patientUuid: ID!) {
    patientIsRegistered (userUuid: $userUuid, patientUuid: $patientUuid) 
  }
`;

// User
export const GET_USER_UUID = gql`
  query {
    user {
      uuid
      patientUuid
      name
      surname
    }
  }
`;

export const FIND_USER_BY_UUID = gql`
  query ($uuid: ID!) {
    userByUuid (uuid: $uuid) {
      uuid
      name
      surname
      email
    }
  }
`;

export const FIND_USER_PROFILE_BY_UUID = gql`
  query ($uuid: ID!) {
    userByUuid (uuid: $uuid) {
      uuid
      email
      name
      surname
      profilePhoto
    }
  }
`;

export const FIND_USER_BY_EMAIL = gql`
  query ($email: String!) {
    userByEmail (email: $email) {
      uuid
      email
      name
      surname
      profilePhoto
    }
  }
`;

export const GET_USER_FOR_EDITING = gql`
  query {
    user {
      uuid
      name
      surname
      email
      personalId
      professionalRole
      specialty
      subspecialty
      collegiateNumber
      profilePhoto
    }
  }
`;

export const GET_PATIENT_USER_FOR_EDITING = gql`
  query {
    user {
      uuid
      name
      surname
      personalId
      profilePhoto
    }
  }
`;

export const GET_USER_FOR_PREVIEW = gql`
  query {
    user {
      uuid
      name
      surname
      profilePhoto
      apiVersion
      bridgeVersion
    }
  }
`;

export const GET_BILLING_PLANS = gql`
  query {
    billingPlans {
      uuid
      name
      description
      price
      users
      storage
      credits
      supportHours
      dicomDownload
      defaultForNewUsers
      hideInDefaultView
    }
  }
`;

export const GET_USER_BILLING_PLAN = gql`
  query {
    userBillingPlan {
      uuid
      userUuid
      consumedUsers
      consumedStorage
      consumedCredits
      consumedSupportHours
      consumedDicomDownload
      addonPaymentsPayerRef
      addonPaymentsCardRef
      cardLastNumbers
      nextBillingDate
      countryCode
      province
      town
      postalCode
      address
      createdAt
      updatedAt
      billingPlan {
        uuid
        name
        price
        description
        users
        storage
        credits
        supportHours
        dicomDownload
        createdAt
        updatedAt
      }
      nextBillingPlan {
        uuid
        name
        price
      }
    }
  } 
`;
