import styled from 'styled-components';

export const TextButton = styled.button`
  background-color: transparent;
  font-weight: 600;
  color: ${(props) => (props.danger ? props.theme.colors.danger : props.theme.colors.primary)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  border: none;
  padding: 0;
  margin-right: 5px;
  cursor: default;
`;

export const BoldTextButton = styled(TextButton)`
  font-weight: 800;
`;

export const LinkTextButton = styled(TextButton)`
  cursor: pointer;
`;
