import React from 'react';
import { useSelector } from 'react-redux';

import { StyledWorkspaceName } from './styled/WorkspaceName';
import { ExtraBold } from '../Common/styled/Text';

/* eslint-disable react/jsx-props-no-spreading */

export default (props) => {
  const currentHospital = useSelector((state) => state.hospital);
  const currentProvider = useSelector((state) => state.provider);
  const { type } = props;

  return (
    <StyledWorkspaceName {...props}>
      <ExtraBold>{type === 'provider' ? currentProvider.name : currentHospital.name}</ExtraBold>
    </StyledWorkspaceName>
  );
};
