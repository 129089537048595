import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { ImportStudyWidgetItem } from './ImportStudyWidgetItem';
import { getLocalStorageJson } from '../../../utils/ReadStorageUtils';

export const ImportStudyWidget = () => {
  const dispatch = useDispatch();
  const displayQueue = useSelector((state) => state.userInterface.importStudyQueue);
  const localImportStudyQueue = getLocalStorageJson('import-study', []) || [];

  useEffect(() => {
    const storedImportStudyQueue = getLocalStorageJson('import-study', []);
    const updatedImportStudyQueue = localImportStudyQueue && JSON.stringify(localImportStudyQueue.filter((study) => moment().diff(study.exp) < 0));

    if (storedImportStudyQueue !== updatedImportStudyQueue) {
      if (updatedImportStudyQueue === null) {
        dispatch({ type: 'IMPORT_STUDY_QUEUE', importStudyQueue: { importStudyQueue: false } });
        return;
      }
      if (!displayQueue) {
        dispatch({ type: 'IMPORT_STUDY_QUEUE', importStudyQueue: { importStudyQueue: true } });
      }
      localStorage.setItem('import-study', updatedImportStudyQueue);
    }
  }, [localImportStudyQueue, displayQueue]);

  return <ImportStudyWidgetItem localQueue={localImportStudyQueue} />;
};
