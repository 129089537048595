import styled from 'styled-components';

import { Padding } from '../../Common/styled/Paddings';
import { ExtraBold } from '../../Common/styled/Text';

export const StyledWorkspaceName = styled.div`
  ${ExtraBold} {
    font-size: ${(props) => props.theme.font.size.large};
    margin: 0;
  }

  ${Padding}
`;
