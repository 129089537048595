import React from 'react';
import moment from 'moment-timezone';

import { getDate, getFormattedDate, getFormattedDateTime, getFormattedTime } from '../../utils/dateTimeUtils';

export const FromNow = ({ date, years, parentheses, bold }) => {
  let formattedDate;
  const momentDate = getDate(date);

  if (!momentDate) return <span />;

  if (years && typeof years === 'number' && moment().subtract(years, 'years') > momentDate) {
    formattedDate = momentDate.format('L');
  } else {
    formattedDate = momentDate.fromNow();
  }
  formattedDate = bold ? <b>{ formattedDate }</b> : formattedDate;

  if (parentheses) return <span>{`(${formattedDate})`}</span>;

  return <span>{formattedDate}</span>;
};

export const LocaleDate = (props) => (
  <span>{` ${getFormattedDate(props)} `}</span>
);

export const LocaleTime = (props) => (
  <span>{` ${getFormattedTime(props)} `}</span>
);

export const LocaleDateTime = (props) => (
  <span>{` ${getFormattedDateTime(props)} `}</span>
);
