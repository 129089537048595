import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Viewer from 'react-viewer';

export default () => {
  const dispatch = useDispatch();
  const showViewer = useSelector((state) => state.userInterface.showViewer);
  const viewerImages = useSelector((state) => state.userInterface.viewerImages);
  const closeViewer = () => dispatch({ type: 'TOGGLE_VIEWER', showViewer: { showViewer: false } });

  return (
    <Viewer style={{ zIndex: 1300 }} visible={showViewer} images={viewerImages} onClose={closeViewer} onMaskClick={closeViewer} noToolbar />
  );
};
