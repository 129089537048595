import i18n from 'i18next';

export const DefaultLanguage = 'en';

export const LocaleList = [
  { id: 1, value: 'en', label: 'EN' },
  { id: 2, value: 'es', label: 'ES' },
  { id: 2, value: 'ca', label: 'CA' },
];

export const getNavigatorUserLanguage = () => {
  const navLang = navigator.language || navigator.userLanguage;

  if (navLang.includes('en')) return 'en';
  if (navLang.includes('es')) return 'es';
  if (navLang.includes('ca')) return 'ca';
  if (navLang.includes('eu')) return 'es';
  if (navLang.includes('gl')) return 'es';

  return DefaultLanguage;
};

export const getLocaleDefault = () => {
  const language = i18n.language;
  if (!language) return DefaultLanguage;
  if (LocaleList.map((locale) => locale.value).includes(language)) return language;
  return getNavigatorUserLanguage();
};

export const getLocaleDateFormat = (weekday = false) => {
  const weekdayInfo = weekday ? ' (dddd)' : '';
  const formats = {
    en: {
      field: 'MM / dd / yyyy',
      placeholder: 'mm / dd / yyyy',
      format: 'MM/DD/YYYY',
      label: `MMM Do, YYYY${weekdayInfo}`,
      weekdayParentheses: 'MMM Do, YYYY (dddd)',
      weekdayComma: 'dddd, MMM Do YYYY',
    },
    es: {
      field: 'dd / MM / yyyy',
      placeholder: 'dd / mm / aaaa',
      format: 'DD/MM/YYYY',
      label: `D MMM YYYY${weekdayInfo}`,
      weekdayParentheses: 'D MMM YYYY (dddd)',
      weekdayComma: 'dddd, D MMM YYYY',
    },
    ca: {
      field: 'dd / MM / yyyy',
      placeholder: 'dd / mm / aaaa',
      format: 'DD/MM/YYYY',
      label: `D MMM YYYY${weekdayInfo}`,
      weekdayParentheses: 'D MMM YYYY (dddd)',
      weekdayComma: 'dddd, D MMM YYYY',
    },
  };
  return formats[getLocaleDefault()] || formats.en;
};
