/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { margin } from '../../../styles/mixins';
import { sizes } from '../../../styles/variables';

const Base = css`
  ${(props) => margin(props)}
`;

export const Button = styled.button`
  min-width: 130px;
  min-height: 34px;
  padding: .375em;
  font-weight: 600;
  margin: ${(props) => (props.margin ? `${props.margin}% 0` : '5% 0')};
  float: ${(props) => (props.float ? props.float : 'none')};
  cursor: pointer;

  color: ${(props) => (props.danger ? props.theme.colors.danger : props.theme.colors.primary)};
  border: 1px solid ${(props) => (props.danger ? props.theme.colors.danger : props.theme.colors.primary)};

  ${(props) => props.disabled && css`
    color: ${props.theme.colors.primary}40;
    border-color: ${props.theme.colors.primary}40;
  `}

  // Don't do anything on hover if button is disabled
  ${(props) => !props.disabled && css`
    :hover,&.active {
      color: ${props.theme.colors.white};
      background-color: ${(props.danger ? props.theme.colors.danger : (props.backgroundColor ? props.backgroundColor : props.theme.colors.primary))};
      box-shadow: 1px 2px 2px 0 #00000010;
    }
  `};

  :focus {
    outline: none;
  }

  :disabled {
    border: 1px solid ${(props) => props.theme.colors.gray300};
    background: ${(props) => props.theme.colors.gray200};
    color: ${(props) => props.theme.colors.gray600};
    & :hover {
      cursor: not-allowed;
    }
  }

  @media (max-width: ${sizes.xsMax}) {
    width: 100%;
    min-width: auto;
  }

  ${Base}
`;

export const RoundedButton = styled(Button)`
  border-radius: 60px;
  color: white;
  background-color: ${(props) => (props.backgroundColor ? `${props.backgroundColor}e3` : `${props.theme.colors.primary}e3`)};
  :not(:disabled) {
    box-shadow: #0008 -1px 2px 3px;
    :hover {
      box-shadow: #0006 -3px 6px 4px;
    }
  }
  transition: background-color .375s, box-shadow .375s;
`;

export const SecondaryButton = styled(Button)`
  border-color: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.secondaryText};

  :hover {
    background-color: ${(props) => props.theme.colors.secondary};
  }
`;

const LongButtonBase = css`
  min-width: 200px;
`;

const FillButtonBase = css`
  min-width: 200px;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : props.theme.colors.primary)};
  color: ${(props) => props.theme.colors.white};
`;

export const LongButton = styled(Button)`
  ${LongButtonBase}
`;

export const FillButton = styled(Button)`
  ${FillButtonBase}
`;

export const SecondaryLongButton = styled(SecondaryButton)`
  ${LongButtonBase}
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin-right: 5%;
  }

  :last-child {
    margin-right: 0;
  }

  ${Base}
`;
