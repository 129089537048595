import React from 'react';

import { Helmet, HelmetProvider } from 'react-helmet-async';

import { useStaticQuery, graphql } from 'gatsby';

export default () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  return (
    <HelmetProvider>
      <Helmet
        title={site.siteMetadata.title}
        meta={[
          {
            name: 'description',
            content: site.siteMetadata.description,
          },
        ]}
      />
    </HelmetProvider>
  );
};
