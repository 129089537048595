export const isNullOrEmptyString = (string) => {
  if (string === null) return true;
  return string.trim() === '';
};

export const capitalize = (string) => (string.charAt(0).toUpperCase() + string.slice(1));

export const capitalizeAll = (string) => string && string.split(' ').map((word) => capitalize(word)).join(' ');

export const encode_utf8 = (string) => {
  try {
    return unescape(encodeURIComponent(string));
  } catch (e) {
    return string;
  }
};

export const decode_utf8 = (string) => {
  try {
    return decodeURIComponent(escape(string));
  } catch (e) {
    return string;
  }
};
