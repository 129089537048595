import { makeStyles } from '@material-ui/core';

const functionStyles = makeStyles((theme) => ({
  disabled: {
    color: theme.palette.grey.A200,
  },
  chip: {
    marginTop: -2,
  },
  transparentChip: {
    marginTop: -2,
    minWidth: 115,
    background: 'transparent',
    border: '1px solid',
    color: theme.palette.primary.main,
    borderColor: `${theme.palette.grey.A200}57`,
    '& > span': {
      fontSize: '1.25rem',
    },
    '&:hover': {
      background: theme.palette.primary.light,
      color: 'white',
      transition: 'all .25s ease',
    },
  },
}));

export default functionStyles;
