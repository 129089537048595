import React from 'react';

import { Can } from '@casl/react';
import { useKeycloak } from 'react-keycloak';
import { useQuery } from 'react-apollo';
import { useSelector } from 'react-redux';
import ability from './ability';
import { GET_HOSPITAL_GROUPS_FOR_PREVIEW } from '../graphql/queries';


export default ({ children, ...props }) => {
  const [keycloak] = useKeycloak();
  const uuid = useSelector((state) => state.hospital.uuid);
  const { error, loading, data: hospitalData } = useQuery(GET_HOSPITAL_GROUPS_FOR_PREVIEW, { variables: { uuid } });

  if (loading || error) return null;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Can {...props} ability={ability(keycloak.tokenParsed, hospitalData)}>
      {children}
    </Can>
  );
};

export const canI = (action, subject) => {
  const [keycloak] = useKeycloak();
  const uuid = useSelector((state) => state.hospital.uuid);
  const { error, loading, data: hospitalData } = useQuery(GET_HOSPITAL_GROUPS_FOR_PREVIEW, { variables: { uuid } });

  if (loading || error) return null;

  return ability(keycloak.tokenParsed, hospitalData).can(action, subject);
};

export const cannotI = (action, subject) => !canI(action, subject);
