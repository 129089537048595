import React, { forwardRef } from 'react';

import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  tooltipPlacementTop: {
    margin: '4px 0',
  },
});

export default forwardRef((props, ref) => {
  const classes = useStyles();
  const { children, arrow, title, open, onOpen, onClose } = props;
  return (
    <Tooltip
      ref={ref}
      className={classes.tooltipPlacementTop}
      arrow={arrow}
      title={title}
      TransitionComponent={Zoom}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
    >
      {children}
    </Tooltip>
  );
});
