import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip, Tooltip, Typography, Zoom } from '@material-ui/core';
import { Description, HowToRegTwoTone, Lock, LockOpen, InsertDriveFileOutlined } from '@material-ui/icons';
import {
  getFormattedDate,
  getFormattedDateTime,
  getDateTimeOption,
  getFromNowTime,
  getDateTimeFromNowTimeObject,
  getDateYears,
} from './dateTimeUtils';
import { ParseNotificationMessage } from './NotificationUtils';
import { getProtocolStateLabel } from './ProtocolUtils';
import functionStyles from './functionStyles';
import { capitalizeAll, decode_utf8, encode_utf8 } from './StringUtils';

export const DATE_FORMAT = 'DATE_FORMAT';
export const DATE_FORMAT_CHIP = 'DATE_FORMAT_CHIP';
export const DATE_FORMAT_CHIP_PRIMARY = 'DATE_FORMAT_CHIP_PRIMARY';
export const TIME_FORMAT = 'TIME_FORMAT';
export const DATETIME = 'DATETIME';
export const DATETIME_FORMAT = 'DATETIME_FORMAT';
export const DATETIME_OPTION = 'DATETIME_OPTION';
export const FROM_NOW = 'FROM_NOW';
export const YEARS = 'YEARS';
export const DATETIME_FROM_NOW_OBJECT = 'DATETIME_FROM_NOW_OBJECT';
// export const PROFILE = 'PROFILE';
export const CAPITALIZED = 'CAPITALIZED';
export const PATIENT_NAME = 'PATIENT_NAME';
export const PATIENT_NAME_CAPITALIZED = 'PATIENT_NAME_CAPITALIZED';
export const PERCENT = 'PERCENT';
export const MESSAGE = 'MESSAGE';
// export const MATERIAL = 'MATERIAL';
export const SIZE = 'SIZE';
export const SEP_TO_UPPER = 'SEP_TO_UPPER';
export const LENGTH = 'LENGTH';
export const PROTOCOL_STATE = 'PROTOCOL_STATE';
export const BOOLEAN = 'BOOLEAN';
export const ACTIVE_CASE = 'ACTIVE_CASE';
export const ACTIVE_CASE2 = 'ACTIVE_CASE2';
export const VIRTUAL_VISIT_REPLY = 'VIRTUAL_VISIT_REPLY';
export const LOCALE = 'LOCALE';
export const LOCALE_TO_UPPER = 'LOCALE_TO_UPPER';
export const IS_REGISTERED = 'IS_REGISTERED';
export const TEXT_HIGHLIGHT = 'TEXT_HIGHLIGHT';
export const TEXT_HIGHLIGHT_STRONG = 'TEXT_HIGHLIGHT_STRONG';
export const TEXT_STRONG = 'TEXT_STRONG';
export const CHIP_HIGHLIGHT_TRANSPARENT = 'CHIP_HIGHLIGHT_TRANSPARENT';
export const SPECIALTY = 'SPECIALTY';
export const UTF8_ENCODE = 'UTF8_ENCODE';
export const UTF8_DECODE = 'UTF8_DECODE';

function SepToUpper(value, sep) {
  let result = '';

  for (let i = 0; i < value.length; i++) {
    let isUpperCase = (i > 0 && value.charAt(i) === value.charAt(i).toUpperCase());
    result += (isUpperCase) ? sep + value.charAt(i) : value.charAt(i);
  }

  return result;
}

// export function Profile(value) {
//   const regex = new RegExp(/(?<=Profile)[\w]+/g);
//   return value && `${regex.exec(value)}`;
// }

// export function Material(value) {
//   const regex = new RegExp(/(?<=MaterialHP3DHR)[\w]+/g);
//   return value && `HP 3D HR ${regex.exec(value)}`;
// }

export function Size(value) {
  let size = parseInt(value, 10);
  let type = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  for (let i = 0; i < 5; i++) {
    if (size <= 1024) return `${size.toFixed(2)} ${type[i]}`;
    size /= 1024;
  }

  return value;
}

export function PatientName(value) {
  return value && `${value.name} ${value.surname}`;
}

export function PatientNameCapitalized(value) {
  return value && capitalizeAll(PatientName(value).toLowerCase());
}

export function DateTime(value) {
  if (!value) return '';

  let date = new Date(value).toLocaleDateString();
  let time = new Date(value).toLocaleTimeString();

  return (date !== 'Invalid Date' && time !== 'Invalid Date')
    ? `${date} ${time}` : '';
}

export function GetActiveCase({ value }) {
  const { t } = useTranslation();
  const styles = functionStyles();
  const description = value ? t('case.open') : t('case.closed');
  return (
    <Tooltip title={description} TransitionComponent={Zoom} placement="bottom" arrow>
      {value
        ? <LockOpen />
        : <Lock className={styles.disabled} />}
    </Tooltip>
  );
}

export function GetVirtualVisitReply({ value }) {
  const { t } = useTranslation();
  const styles = functionStyles();
  const description = value ? t('protocol.completed.by.patient') : t('pending.to.answer');
  return (
    <Tooltip title={description} TransitionComponent={Zoom} placement="bottom" arrow>
      {value
        ? <Description />
        : <InsertDriveFileOutlined className={styles.disabled} />}
    </Tooltip>
  );
}

const RegisteredIconUI = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('registered.platform')} TransitionComponent={Zoom} placement="bottom" arrow>
      <HowToRegTwoTone />
    </Tooltip>
  );
};

const GetIsRegistered = ({ value }) => (value ? <RegisteredIconUI /> : '');

const GetHighlightText = ({ value }) => (
  <Typography color="primary" component="span">
    <Box fontSize="1.3rem" lineHeight={1.75}>
      {value}
    </Box>
  </Typography>
);

const GetStrongText = ({ value }) => (
  <Typography color="textSecondary" component="span">
    <Box fontWeight={700} fontSize="1.3rem" lineHeight={1.75}>
      {value}
    </Box>
  </Typography>
);

const GetChipHighlight = ({ value }) => {
  const styles = functionStyles();
  return <Chip size="small" className={styles.transparentChip} label={value} />;
};

const GetFormattedDateChip = ({ date, color = 'default' }) => {
  const styles = functionStyles();
  return (
    <Chip
      size="small"
      className={`${styles.chip} date`}
      color={color}
      label={getFormattedDate({ date })}
    />
  );
};

const GetFormattedDateChipPrimary = ({ date }) => GetFormattedDateChip({ date, color: 'primary' });

export const GetSpecialty = ({ value }) => value && value.toLowerCase();

export function GetDefault(fields, id) {
  let field = fields.find((item) => item.id === id);
  if (field) {
    let find = field.props.array.find((item) => item.default === 'true');
    if (find) return find.id;
  }

  return undefined;
}

export function GetFormat(value, format) {
  const { t } = useTranslation();

  switch (format) {
    case SEP_TO_UPPER:
      return SepToUpper(value, ' ');
    // case PROFILE:
    //   return ProfileObject(value);
    // case MATERIAL:
    //   return Material(value);
    case MESSAGE:
      return ParseNotificationMessage(value);
    case SIZE:
      return Size(value);
    case DATETIME:
      return DateTime(value);
    case DATETIME_FORMAT:
      return getFormattedDateTime({ date: value });
    case DATETIME_OPTION:
      return getDateTimeOption({ date: value });
    case DATE_FORMAT:
      return getFormattedDate({ date: value });
    case DATE_FORMAT_CHIP:
      return GetFormattedDateChip({ date: value });
    case DATE_FORMAT_CHIP_PRIMARY:
      return GetFormattedDateChipPrimary({ date: value });
    case TIME_FORMAT:
      return new Date(value).toLocaleTimeString();
    case FROM_NOW:
      return getFromNowTime({ date: value });
    case YEARS:
      return getDateYears(value);
    case DATETIME_FROM_NOW_OBJECT:
      return getDateTimeFromNowTimeObject({ date: value });
    case CAPITALIZED:
      return capitalizeAll(value.toLowerCase());
    case PATIENT_NAME:
      return PatientName(value);
    case PATIENT_NAME_CAPITALIZED:
      return PatientNameCapitalized(value);
    case PERCENT:
      return `${parseFloat(value).toFixed(2)} %`;
    case LENGTH:
      return value && value.length;
    case BOOLEAN:
      return value ? t('yes') : t('not');
    case ACTIVE_CASE:
      return value ? t('open.ed') : t('closed');
    case ACTIVE_CASE2:
      return GetActiveCase({ value });
    case VIRTUAL_VISIT_REPLY:
      return GetVirtualVisitReply({ value });
    case PROTOCOL_STATE:
      return t(getProtocolStateLabel(value));
    case LOCALE:
      return t(value.toLowerCase());
    case LOCALE_TO_UPPER:
      return t(value.toLowerCase()).toUpperCase();
    case IS_REGISTERED:
      return GetIsRegistered({ value });
    case TEXT_HIGHLIGHT:
      return GetHighlightText({ value });
    case TEXT_STRONG:
      return GetStrongText({ value, color: 'inherit' });
    case CHIP_HIGHLIGHT_TRANSPARENT:
      return GetChipHighlight({ value, className: 'transparent' });
    case SPECIALTY:
      return t(GetSpecialty({ value }));
    case UTF8_ENCODE:
      return encode_utf8(value);
    case UTF8_DECODE:
      return decode_utf8(value);
    default:
      return (value !== undefined) ? value : '';
  }
}

export function GetValueToPath(obj, path) {
  const split = path.replace(/\[/g, '.').replace(/]/g, '').split('.');
  let value = obj;

  split.forEach((key) => {
    if (value) value = value[key];
  });

  return value;
}

export function GetValue(item, field) {
  const split = field.id.split('.');
  let value = item;
  split.forEach((key) => {
    if (value) value = value[key];
  });

  value = (value !== null) ? value : '';

  return GetFormat(value, field.format);
}

// eslint-disable-next-line no-underscore-dangle
export const GetItemType = (item) => item.__typename;
