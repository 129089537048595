import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 285;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  separator: {
    height: 10,
    width: '100%',
  },
  icon: {
    position: 'absolute',
    right: 5,
    top: 5,
    zIndex: 999,
  },
  fab: {
    position: 'absolute',
    left: 0,
    top: 8,
    paddingLeft: drawerWidth - 27,
    zIndex: 1400,
    '@media (max-width:1099px)': {
      top: 4,
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      zIndex: 10,
      boxShadow: '0 0 .75em #00000057',
      scrollbarWidth: 'none',
    },
    '& .MuiDrawer-paper::-webkit-scrollbar': {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  noDrawer: {
    display: 'none',
  },
  userData: {
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '2.75rem',
    color: 'white',
    backgroundColor: theme.palette.primary.light,
  },
}));

export default useStyles;
