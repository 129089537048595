import React, { forwardRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from 'react-keycloak'; import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-apollo';
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Slide } from '@material-ui/core';
import { DOWNLOAD_DICOM_STUDY } from '../../../graphql/mutations';
import { DownloadDicomCD, DownloadDicomFile, ProvideDownloadedBlob } from '../../../utils/DicomFileUtils';
import { graphQLErrorParse } from '../../../utils/ErrorGraphQLUtils';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 600,
      padding: 15,
      '@media (max-width:600px)': {
        padding: 0,
        margin: '.75em',
      },
      '& > div > h2': {
        fontWeight: 600,
        lineHeight: 1.3,
      },
    },
  },
  min: {
    position: 'absolute',
    left: 2,
    bottom: 2,
    zIndex: 1500,
    fontSize: '1.1875rem',
    fontWeight: 600,
    '& .MuiCircularProgress-root': {
      margin: '2px 0 -4px 8px',
    },
  },
}));

export const StudyDownloadWidget = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const downloadingStudies = useSelector((state) => state.downloadingStudies);
  const [studies, setStudies] = useState(0);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState('');
  const [progress, setProgress] = useState(0);
  const [keycloak] = useKeycloak();

  if (!downloadingStudies) return '';

  if (studies && !downloadingStudies.length) setStudies(0);

  if (downloadingStudies.length > studies) {
    setStudies(downloadingStudies.length);
    setOpen(true);
  }

  const study = downloadingStudies && downloadingStudies[0];

  const dicomPrefix = type === 'cd' ? 'lite_cd_alma3d_study_' : 'alma3d_study_';
  const downloadDicom = type === 'cd' ? DownloadDicomCD : DownloadDicomFile;

  const clearDownload = () => {
    dispatch({ type: 'REMOVE_STUDY_DOWNLOAD', uuid: study.uuid });
    setDownloading(false);
    setOpen(false);
  };

  const DownloadProcess = async (response) => {
    if (!response) return;
    if (!response.body) return;

    setProgress(0);

    const contentLength = response.headers.get('Content-Length');
    const totalLength = typeof contentLength === 'string' && parseInt(contentLength, 10);
    const reader = response.body.getReader();
    const chunks = [];

    let receivedLength = 0;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      // eslint-disable-next-line no-await-in-loop
      const { done, value } = await reader.read();
      if (done) {
        setTimeout(() => setDownloadStatus('completed'), 750);
        break;
      }
      chunks.push(value);
      receivedLength += value.length;
      if (typeof totalLength === 'number') {
        const step = (receivedLength / totalLength).toFixed(2) * 100;
        setProgress(step);
      }
    }
    // eslint-disable-next-line consistent-return
    return new Blob(chunks);
  };

  const [downloadDicomStudy, { loading }] = useMutation(
    DOWNLOAD_DICOM_STUDY,
    {
      onCompleted: ({ dicomStudyDownload }) => {
        setDownloadStatus('processing.requested.download');
        downloadDicom(dicomStudyDownload, keycloak)
          .then((response) => {
            if (!response.ok) {
              toast(`Error in file download: ${response.status} ${response.statusText}`, { className: 'toast-error' });
              clearDownload();
              return;
            }
            setProgress(0);
            setDownloadStatus('downloading');
            setTimeout(() => {
              DownloadProcess(response)
                .then((blob) => {
                  const filename = `${dicomPrefix}${dicomStudyDownload.title}.zip`;
                  ProvideDownloadedBlob(blob, filename);
                  setTimeout(() => {
                    clearDownload();
                    setProgress(0);
                  }, 1500);
                });
            }, 750);
          })
          .catch((error) => {
            console.log(error);
            clearDownload();
          });
      },
      onError: (error) => {
        toast(graphQLErrorParse(error), { className: 'toast-error' });
        clearDownload();
      },
    },
  );

  // const onClear = () => { if (study) dispatch({ type: 'REMOVE_STUDY_DOWNLOAD', uuid: study.uuid }); };
  const onClose = () => setOpen(false);
  const onClick = () => setOpen(true);

  const download = async (uuid) => {
    downloadDicomStudy({ variables: { uuid } }).then();
  };

  if (study && !downloading) {
    setDownloading(true);
    setType(study.type);
    download(study.uuid).then();
  }

  return (
    <>
      {study && (
        <Button
          variant="outlined"
          onClick={onClick}
          className={classes.min}
          style={{ display: open ? 'none' : 'block' }}
        >
          {study.name}
          {['downloading', 'completed'].includes(downloadStatus)
            ? <CircularProgress variant="determinate" size={18} thickness={6} value={progress} />
            : <CircularProgress color="secondary" size={18} thickness={6} />}
        </Button>
      )}
      <Dialog
        className={classes.dialog}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
      >
        <DialogTitle>
          {loading
            ? t('requesting.study.download')
            : t(downloadStatus)}
        </DialogTitle>
        <DialogContent>
          {['downloading', 'completed'].includes(downloadStatus)
            ? <LinearProgress variant="determinate" color="primary" value={progress} />
            : <LinearProgress color="secondary" />}
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};
