import React, { useContext } from 'react';
import IcomoonReact from 'icomoon-react';
import { ThemeContext } from 'styled-components';
import { CircularProgressbar } from 'react-circular-progressbar';
import icons from '../../../icons/cloud.json';
import { StyledUploadItem, StyledUploadItemTitle, StyledUploadItemStatus } from './styled/StyledStudyUploadItem';
import 'react-circular-progressbar/dist/styles.css';
import iconsInfo from '../../../icons/iconsInfo';

export const StudyUploadItem = ({ uploadingStudy }) => {
  const title = () => {
    if (uploadingStudy.description.length < 25) {
      return uploadingStudy.description;
    }
    return `${uploadingStudy.description.substring(0, 24)}...`;
  };
  const percentage = () => ((uploadingStudy.filesCompleted / uploadingStudy.files.length) * 100);
  const themeContext = useContext(ThemeContext);

  return (
    <StyledUploadItem>
      <StyledUploadItemTitle>
        <IcomoonReact iconSet={icons} color={themeContext.colors.gray400} size={20} icon={iconsInfo.file_DICOM.name} />
        {title()}
      </StyledUploadItemTitle>
      <StyledUploadItemStatus>
        {`${uploadingStudy.filesCompleted}/${uploadingStudy.files.length}`}
        <CircularProgressbar
          value={percentage()}
          strokeWidth="13"
          circleOneStroke={themeContext.colors.gray800}
          circleTwoStroke={themeContext.colors.primary}
        />
      </StyledUploadItemStatus>
    </StyledUploadItem>
  );
};
