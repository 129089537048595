import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { Cancel, Warning } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  licenseAlert: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 'calc(100% - 2em)',
    height: 'calc(100% - 2em)',
    margin: '1em',
    color: '#ffffffc7',
    backgroundColor: '#ad373af0',
    zIndex: 9,
    border: 'darkred 1px solid',
    borderRadius: 8,
    boxShadow: '0 2px 6px #0009',
    '@media (max-width:600px)': {
      maxWidth: 'calc(100% - .5em)',
    },
  },
  close: {
    position: 'absolute',
    right: 0,
    margin: '.5em',
    cursor: 'pointer',
    color: 'white',
    boxShadow: '1px 1px 4px #000a',
    borderRadius: 12,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '4em',
    '& > *': {
      width: 'calc(100% - 4em)',
      margin: '0 auto',
      whiteSpace: 'normal',
      textAlign: 'center',
      '@media (max-width:991px)': {
        width: 'calc(100% - 2em)',
      },
      '@media (max-width:600px)': {
        width: 'calc(100% - 0.5em)',
      },
    },
    '& > h3': {
      fontWeight: 700,
      marginBottom: '.5em',
      color: '#ffffffdf',
      '& > svg': {
        fontSize: '1.25em',
        marginBottom: -12,
        marginRight: 10,
      },
    },
    '& > h4': {
      fontWeight: 600,
      '@media (max-width:600px)': {
        fontSize: '1.875em',
      },
    },
  },
}));

export const LicenseWarning = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch({ type: 'SET_LICENSE', licenseWarning: { licenseWarning: false } });
  };

  return (
    <Box className={styles.licenseAlert}>
      <Cancel className={styles.close} onClick={onClose} />
      <Box className={styles.container}>
        <Typography variant="h3">
          <Warning />
          {t('expired.license')}
        </Typography>
        <Typography variant="h4">{t('expired.license.details')}</Typography>
        <Typography variant="h4">{t('expired.license.contact')}</Typography>
      </Box>
    </Box>
  );
};
