import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'gatsby';

import { ArrowForwardIos } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { LinkTextUI } from '../../componentsUI/LinkText';
import ApplicationPathWrapperUI from '../../componentsUI/Navbar/ApplicationPathWrapperUI';

const useStyles = makeStyles(() => ({
  link: {
    maxWidth: 300,
    '@media (max-width:1199px)': {
      maxWidth: 250,
    },
    '@media (max-width:991px)': {
      maxWidth: 200,
    },
    '@media (max-width:600px)': {
      maxWidth: 150,
    },
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:hover': {
      textDecoration: 'none !important',
    },
  },
  arrow: {
    fontSize: 12,
    marginTop: 4,
  },
}));

export default ({ patient, medicalCase, study, advancedTool }) => {
  const styles = useStyles();
  const hospital = useSelector((state) => state.hospital);
  const provider = useSelector((state) => state.provider);

  const hospitalName = hospital && hospital.name;
  const providerName = provider && provider.name;

  const PathLink = ({ path, first = false }) => (
    <>
      {!first && <ArrowForwardIos color="primary" className={styles.arrow} />}
      <Link to={path.link} className={styles.link}>
        <LinkTextUI variant="inherit" color="primary">
          {path.name}
        </LinkTextUI>
      </Link>
    </>
  );

  return (
    <ApplicationPathWrapperUI>
      {!advancedTool
        ? (hospitalName && <PathLink path={{ name: hospitalName, link: '/dashboard' }} first />)
        : (providerName && <PathLink path={{ name: providerName, link: '/advanced-tools' }} first />)}
      {!!patient && <PathLink path={patient} />}
      {!!medicalCase && hospital.menuOptionMedicalCases && <PathLink path={medicalCase} />}
      {!!study && <PathLink path={study} />}
      {!!advancedTool && <PathLink path={advancedTool} />}
    </ApplicationPathWrapperUI>
  );
};
