import React from 'react';

import { Box, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

import { sizes } from '../../../styles/variables';
import { SortWrapper } from './SortWrapper';

const useStyles = makeStyles(() => ({
  navbar: {
    zIndex: 4,
    width: 'calc(100% - 3.5em)',
    padding: '0 1.75em',
    borderBottom: '1px solid #00000018',
    backgroundColor: '#e7e7e7',
  },
}));

export const NavbarWrapper = styled.div`
  width: 100%;
  min-height: 60px;

  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 2px 4px rgba(229, 229, 229, 0.9);
  
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0 2.5%;
  box-sizing: border-box;
  z-index: 8;

  ${SortWrapper} {
    margin-left: auto;
  }

  // @media (min-width: ${sizes.mdLgMin}) {
    // width: calc(100vw - ${(props) => props.theme.sidebarWidth});
  // }

  @media (max-width: ${sizes.xsMax}) {
    padding: 0 4%;
  }
`;

export const NavbarActions = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;

  a, button {
    margin-left: 2%;
    text-decoration: none;
    white-space: nowrap;
  }
`;

export const Navbar = ({ children }) => {
  const styles = useStyles();

  return (
    <Box className={styles.navbar}>
      {children}
    </Box>
  );
};
