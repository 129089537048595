import styled from 'styled-components';
import { sizes } from '../../../styles/variables';

const gray = (props) => (props.theme.colors.gray100);

export const ContentWrapper = styled.div`
  width: 100%;
  // background-color: ${(props) => props.theme.colors.gray100};
  // background-color: white;
  background-image: linear-gradient(${(props) => gray(props)}, ${(props) => gray(props)} 70%, white);
  flex-direction: column;
  display: flex;

  // @media (min-width: ${sizes.mdLgMin}) {
  //   ${({ fixSidebar, theme }) => fixSidebar && `
  //     margin-left: ${theme.sidebarWidth};
  //   `}
  // }
`;
