import styled from 'styled-components';

import { transition } from '../../../../styles/mixins';
import { Card } from '../../../Common/styled/Card';

export const AutocompleteWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  cursor: pointer;
  z-index: 5;
  :not(:empty) {
    box-shadow: ${(props) => props.theme.floatingBoxShadow};
  }

  ${Card}
  :not(.autocomplete__loading)
  :hover {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.primary};
    ${transition('all .25s ease')};

    svg path {
      fill: ${(props) => props.theme.colors.white};
      ${transition('all .25s ease')};
    }
  }
`;
