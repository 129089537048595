import React, { useContext } from 'react';

import { ToastContainer as ReactToastifyContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ThemeContext } from 'styled-components';

import IcomoonReact from 'icomoon-react';
import icons from '../../../icons/cloud.json';

import { ToastStyles } from './styled/ToastStyles';

export const ToastContainer = () => {
  const themeContext = useContext(ThemeContext);

  const CustomToastClose = ({ closeToast }) => (
    <IcomoonReact iconSet={icons} color={themeContext.colors.gray600} size={10} icon="close" onClick={closeToast} />
  );

  return (
    <ToastStyles>
      <ReactToastifyContainer
        className="toast-container"
        toastClassName="toast"
        progressClassName="toast-progress"
        closeButton={<CustomToastClose />}
      />
    </ToastStyles>
  );
};
