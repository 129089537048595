import { createGlobalStyle } from 'styled-components';

const GlobalThemeStyles = createGlobalStyle`
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default GlobalThemeStyles;
