import { useLayoutEffect, useState } from 'react';

export const useMinWidth = (width) => {
  const [state, setState] = useState(false);

  useLayoutEffect(() => {
    const updateSize = () => {
      const isMin = window.innerWidth < parseFloat(width);

      if (state !== isMin) setState(isMin);
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, [state]);

  return state;
};

export default useMinWidth;
