import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Zoom from '@material-ui/core/Zoom';
import {
  CardMembership,
  Email as EmailIcon,
  ExitToApp,
  InfoOutlined,
} from '@material-ui/icons';

import { version } from '../../../../package.json';
import { isEmpty } from '../../../utils/ObjectUtils';
import { useApplicationInterface } from '../../../utils/ApplicationInterfaceUtils';
import { GET_USER_FOR_PREVIEW } from '../../../graphql/queries';
import UserAccountItem from './UserAccountItem';
import UserAccountMenu from './UserAccountMenu';
import UserAvatar from './UserAvatar';
import UserAccountProfile from './UserAccountProfile';
import UserIconTooltip from './UserIconTooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: '3em',
    color: theme.palette.grey.A400,
    '&.Mui-disabled': {
      color: '#FFFFFFF7',
      backgroundImage: `linear-gradient(to right, ${theme.palette.grey.A400}, ${theme.palette.grey.A100})`,
      lineHeight: 1,
      fontSize: '.9375em',
      fontWeight: '600',
      padding: '.3em .5em',
      borderTop: '1px solid #0000001F',
      borderBottom: '1px solid #0000001F',
    },
    '& .MuiListItemText-root': {
      marginTop: '6px',
    },
    '& .MuiListItemIcon-root': {
      // color: theme.palette.primary.main,
      '& > svg': {
        color: '#666',
        height: '1.125em',
        width: '1.125em',
      },
    },
    '&:hover': {
      // backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.black,
        '& > svg': {
          color: theme.palette.primary.dark,
        },
      },
    },
  },
  icon: {
    padding: 0,
  },
  large: {
    // margin: theme.spacing(1),
    cursor: 'pointer',
    width: theme.spacing(6),
    height: theme.spacing(6),
    border: '1px grey solid',
  },
  initials: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    fontSize: '.9375em',
    cursor: 'pointer',
  },
  version: {
    fontSize: '1.0625rem',
    lineHeight: 1.4,
  },
}));

const { doctorApp } = useApplicationInterface();

export default ({ linkTo, openUrl, logout }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [displayTooltip, setTooltip] = useState(false);
  const { loading, data } = useQuery(GET_USER_FOR_PREVIEW);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const ref = useRef(null);

  const handleShowTooltip = () => { if (!anchorEl) setTooltip(true); };
  const handleHideTooltip = () => setTooltip(false);

  const handleClose = () => setAnchorEl(null);
  const handleUserSettings = () => linkTo('/user/settings/edit');
  const handleUserIcon = (event) => {
    setTooltip(false);
    setAnchorEl(event.currentTarget);
  };

  const handleContact = () => openUrl({
    url: 'https://alma-medical.com/en/contact/',
    target: '_blank',
    rel: 'noopener noreferrer',
  });

  const handleVersion = () => {
    dispatch({
      type: 'RELEASE_NOTES',
      releaseNotes: {
        releaseNotes: {
          display: true,
          type: 'all',
        },
      },
    });
  };

  if (loading || isEmpty(data)) return null;

  const user = data && data.user;
  const frontendVersion = version.replace('-rel', '');
  const backendVersion = user && user.apiVersion ? user.apiVersion : frontendVersion;
  const bridgeVersion = user && user.bridgeVersion;

  const UserAccountPlanSettings = () => (
    <UserAccountItem onClick={() => linkTo('/user/settings/plan')}>
      <ListItemIcon>
        <CardMembership fontSize="small" />
      </ListItemIcon>
      <ListItemText>{t('user.plan')}</ListItemText>
    </UserAccountItem>
  );

  const UserAccountLogOut = () => (
    <UserAccountItem onClick={logout}>
      <ListItemIcon>
        <ExitToApp fontSize="small" />
      </ListItemIcon>
      <ListItemText>{t('log.out')}</ListItemText>
    </UserAccountItem>
  );

  const UserAccountContact = () => (
    <UserAccountItem onClick={handleContact}>
      <ListItemIcon>
        <EmailIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>{t('contact')}</ListItemText>
    </UserAccountItem>
  );

  const UserAccountVersion = () => (
    <UserAccountItem onClick={handleVersion}>
      <ListItemIcon>
        <InfoOutlined fontSize="small" />
      </ListItemIcon>
      <Box>
        <Typography className={classes.version}>{`GUI: ${frontendVersion}`}</Typography>
        <Typography className={classes.version}>{`API: ${backendVersion}`}</Typography>
        {bridgeVersion && (
          <Typography className={classes.version}>{`DBI: ${bridgeVersion}`}</Typography>
        )}
      </Box>
    </UserAccountItem>
  );

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <UserIconTooltip
        arrow
        ref={ref}
        title={t('user.profile')}
        TransitionComponent={Zoom}
        open={!anchorEl && displayTooltip}
        onOpen={handleShowTooltip}
        onClose={handleHideTooltip}
      >
        <IconButton
          variant="contained"
          aria-controls="user-profile"
          aria-haspopup="true"
          className={classes.icon}
          onClick={handleUserIcon}
        >
          <UserAvatar user={user} />
          <UserAccountMenu
            id="user-profile"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <UserAccountItem disabled>{`${t('account')}:`}</UserAccountItem>
            <UserAccountProfile onClick={handleUserSettings} />
            {doctorApp && <UserAccountPlanSettings />}
            <Divider />
            <UserAccountLogOut />
            <UserAccountItem disabled>{`${t('help')}:`}</UserAccountItem>
            <UserAccountContact />
            <UserAccountItem disabled>{`${t('version')}:`}</UserAccountItem>
            <UserAccountVersion />
            <Divider />
          </UserAccountMenu>
        </IconButton>
      </UserIconTooltip>
    </ClickAwayListener>
  );
};
