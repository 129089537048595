/*
Queries for:
-----
RemotePAcs
*/
import gql from 'graphql-tag';

// Case
export const GET_REMOTE_PACS_LIST = gql`
  query ($hospitalUuid: ID!) {
    remotePacsList(hospitalUuid: $hospitalUuid) {
      uuid
      hospital {
        uuid
        name
      }
      serverName
      searchType
      createdAt
      dicomNodes {
        uuid
        ip
        port
        aeTitle
        type
      }
    }
  }
`;

export const GET_REMOTE_PACS_DETAIL = gql`
  query($uuid: ID!) {
    remotePacs(uuid: $uuid) {
      uuid
      hospital {
        uuid
        name
      }
      serverName
      searchType
      createdAt
      dicomNodes {
        uuid
        ip
        port
        aeTitle
        type
      }
    }
  }
`;

export const CREATE_REMOTE_PACS = gql`
  mutation ($input: CreateRemotePacsInput!) {
    createRemotePacs(input: $input) {
      serverName
      searchType
      hospital {
        uuid
        name
      }
      dicomNodes {
        uuid
        ip
        port
        aeTitle
        type
      }
    }
  }
`;

export const UPDATE_REMOTE_PACS = gql`
  mutation ($input: UpdateRemotePacsInput!) {
    updateRemotePacs(input: $input) {
      serverName
      searchType
      hospital {
        uuid
        name
      }
      dicomNodes {
        uuid
        ip
        port
        aeTitle
        type
      }
    }
  }
`;

export const DELETE_REMOTE_PACS = gql`
  mutation ($remotePacsUuid: ID!) {
      deleteRemotePacs(remotePacsUuid: $remotePacsUuid)
  }
`;

export const SEARCH_REMOTE_PACS_DICOM_STUDIES = gql`
  query ($remotePacsUuid: ID!, $patientId: String!) {
    remotePacsDicomStudies(remotePacsUuid: $remotePacsUuid, patientId: $patientId) {
      studyInstanceUid
      description
      patientId
      patientName
      patientBirthDate
      modalities
      numberOfSeries
      numberOfInstances
      createdAt
    }
  }
`;

export const ECHO_REMOTE_PACS_DICOM_NODE = gql`
  mutation ($remotePacsUuid: ID!, $ip: String!, $port: Int!, $aeTitle: String!) {
    dicomNodeEcho(
      remotePacsUuid: $remotePacsUuid
      ip: $ip
      port: $port
      aeTitle: $aeTitle
    ) {
      code
      message
    }
  }
`;

export const IMPORT_DICOM_STUDIES = gql`
  mutation ($input: ImportDicomStudiesInput!) {
    importDicomStudies(input: $input) {
      status
      studies {
        dicomStudyUuid
        studyInstanceUid
        requestUuid
      }
      patient {
        uuid
        name
        surname
      }
    }
  }
`;

export const CLEAR_STUDY_FROM_IMPORT_QUEUE = gql`
  mutation ($uuid: ID!) {
    clearStudyFromImportQueue(uuid: $uuid) 
  }
`;

export const GET_IMPORT_DICOM_STUDY_QUEUE = gql`
  query ($requestUuidList: [ID]!) {
    importDicomStudyQueue(requestUuidList: $requestUuidList) {
      uuid
      dicomStudyUuid
      studyInstanceUid
      studyDescription
      requestUuid
      patient {
        uuid
        name
        surname
      }
      studyInstances
      uploadedInstances
      errorInstances
      createdAt
      updatedAt
    }
  }
`;

export const GET_IMPORT_DICOM_STUDY_QUEUE_SUMMARY = gql`
  query ($requestUuidList: [ID]!) {
    importQueue(requestUuidList: $requestUuidList) {
      i
      v
    }
  }
`;
