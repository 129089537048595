import React from 'react';

import { useTranslation } from 'react-i18next';

import { AutocompleteWrapper } from './styled/AutocompleteWrapper';
import { SlimCard } from '../../Common/styled/Card';

const createAutocompleteItems = (items, onSelect) => {
  let error = false;
  if (!Array.isArray(items)) return null;
  items.forEach((item) => { if (!item.key || !item.value) error = true; });

  if (!error) {
    return items.map((item, index) => (
      <AutocompleteItem key={index.toString()} itemKey={item.key} onSelect={onSelect}>
        {item.value}
      </AutocompleteItem>
    ));
  }
  return null;
};

export const Autocomplete = ({ items, onSelect, children }) => (
  <AutocompleteWrapper>
    { items ? createAutocompleteItems(items, onSelect) : children }
  </AutocompleteWrapper>
);

export const AutocompleteItem = ({ itemKey, onSelect, customClass, padding, children }) => (
  <SlimCard
    data-key={itemKey}
    key={itemKey}
    onClick={onSelect}
    className={customClass}
    cursor="pointer"
    padding={padding}
  >
    { children }
  </SlimCard>
);

export const LoadingAutocomplete = () => {
  const { t } = useTranslation();

  return (
    <SlimCard className="autocomplete__loading">{t('loading')}</SlimCard>
  );
};
