import { MenuOptionObjects as options } from './MenuOptionObjects';
import { MenuOptionLists as lists } from './MenuOptionLists';

const optionIsDisabled = (option) => (
  process.env.APPLICATION_DISABLE_OPTIONS
  && process.env.APPLICATION_DISABLE_OPTIONS
    .split(',')
    .map((i) => i.trim())
    .includes(option)
);

export const hospitalMenuOptions = {
  defaultPath: 'dashboard',
  menuOptions: lists.hospital.main
    .filter((k) => !optionIsDisabled(k))
    .map((i) => options.hospital.main[i]),
};

export const patientMenuOptions = {
  defaultPath: 'dashboard',
  menuOptions: lists.patient.main
    .filter((k) => !optionIsDisabled(k))
    .map((i) => options.hospital.main[i]),
};

export const providerMenuOptions = {
  defaultPath: 'advanced-tools',
  menuOptions: lists.provider.main
    .filter((k) => !optionIsDisabled(k))
    .map((i) => options[i]),
};

export const hospitalSettingsMenuOptions = {
  defaultPath: 'settings',
  menuOptions: lists.hospital.settings
    .filter((k) => !optionIsDisabled(k))
    .map((i) => options.hospital.settings[i]),
};

export const userSettingsMenuOptions = {
  defaultPath: 'settings',
  menuOptions: lists.user.doctor
    .filter((k) => !optionIsDisabled(k))
    .map((i) => options.user.settings[i]),
};

export const userPatientSettingsMenuOptions = {
  defaultPath: 'settings',
  menuOptions: lists.user.patient
    .filter((k) => !optionIsDisabled(k))
    .map((i) => options.user.settings[i]),
};
