import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = ({ padding, offsetY }) => makeStyles(() => ({
  container: {
    position: 'relative',
    height: `calc(100vh - ${offsetY}px)`,
    display: 'flex',
  },
  wrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    boxSizing: 'border-box',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  inner: {
    ...(!padding) ? {} : {
      padding,
    },
  },
}))();

export const ScrollableContainer = (props) => {
  const [sentinel, setSentinel] = useState();
  const { children, padding } = props;
  const styles = {
    padding,
    offsetY: sentinel && sentinel.getBoundingClientRect().y,
  };
  const classes = useStyles(styles);
  return (
    <div ref={(i) => setSentinel(i)} className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.inner}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ScrollableContainer;
