import styled from 'styled-components';

export const StyledUploadItem = styled.div`
  padding: 8px 0px;
  margin: 0px 15px;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray400};
  color: ${(props) => props.theme.colors.gray600};
  font-size: ${(props) => props.theme.font.size.small};
  display: flex;
  justify-content: space-between;

  &:last-child {
    border: none;
  }
`;

export const StyledUploadItemTitle = styled.div`
  margin-right: 25px;

  svg {
    margin-right: 10px;
  }
`;

export const StyledUploadItemStatus = styled.div`
  svg {
    width: 14px;
    margin-left: 8px;
  }
  .CircularProgressbar-path {
    stroke: ${(props) => props.theme.colors.primary} !important;
  }
  .CircularProgressbar-trail {
    stroke: ${(props) => props.theme.colors.gray400} !important;
  }
`;
