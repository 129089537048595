import styled from 'styled-components';

import { sizes } from '../../../styles/variables';

const BaseGroup = styled.div`
  display: flex;
  flex: 1;
  flex-grow: ${(props) => (props.long ? 2 : 1)};
`;

export const Row = styled(BaseGroup)`
  flex-direction: row;

  // Rows should be columns in very small screens
  @media (max-width: ${sizes.xsMax}) {
    flex-direction: column;
  }
`;

export const ResponsivelessRow = styled(BaseGroup)`
  flex-direction: row;
`;

export const Column = styled(BaseGroup)`
  flex-direction: column;
`;
