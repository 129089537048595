import styled from 'styled-components';

export const StyledStudyUploadWidget = styled.div`
  position: fixed;
  bottom: 0;
  right: 40px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
  min-width: 250px;
  
  
`;

export const StyledStudyUploadWidgetHeader = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  padding: 8px 15px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
`;

export const StyledStudyUploadWidgetContent = styled.div`
  ${({ isMinimized }) => isMinimized && `
    height: 0;
  `}
`;
