import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogContent, Slide } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';

import Loading from '../Common/Loading';
import { AlertUI } from '../../componentsUI/Alert';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 600,
      padding: 15,
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
  label: {
    color: alpha(theme.palette.text.primary, 0.54),
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
}));

export const SwitcherPatientDialog = ({ open, onClose, loadingUpdate, serverError }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogContent>
        {loadingUpdate && <Loading />}
        {serverError ? t('error.on.hospital.environment.update') : t('updating.hospital.environment')}
        {serverError && <AlertUI severity="error" title="Error">{serverError}</AlertUI>}
      </DialogContent>
    </Dialog>
  );
};
