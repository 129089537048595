import { css } from 'styled-components';

export const transition = (transitionRule) => `
  -moz-transition: ${transitionRule};
  -o-transition: ${transitionRule};
  -webkit-transition: ${transitionRule};
  transition: ${transitionRule};
`;

export const transform = (transforms) => `
  -moz-transform: ${transforms};
  -o-transform: ${transforms};
  -ms-transform: ${transforms};
  -webkit-transform: ${transforms};
  transform: ${transforms};
`;

export const translate3d = (translate) => `
  transform(translate3d(${translate}));
`;

/*
  Margin / Padding customization properties
    t: top margin
    b: bottom
    l: left
    r: right
    x: x axis (left + right)
    y: y axis (top + bottom)

  Examples:
    mt-0: removes margin top
    mx-0: removes margins on X axis (left + right)
*/
export const margin = (props) => css`
  @media screen and (min-width: 0) {
    ${(props['mt-0'] || props['my-0']) && 'margin-top: 0'};
    ${(props['mb-0'] || props['my-0']) && 'margin-bottom: 0'};
    ${(props['ml-0'] || props['mx-0']) && 'margin-left: 0'};
    ${(props['mr-0'] || props['mx-0']) && 'margin-right: 0'};

    ${props['mt-1'] && 'margin-top: 1%'};

    ${props['mt-2'] && 'margin-top: 2%'};
    ${props['ml-2'] && 'margin-left: 2%'};

    ${(props['mt-3'] || props['my-3']) && 'margin-top: 3%'};
    ${(props['mb-3'] || props['my-3']) && 'margin-bottom: 3%'};
    ${(props['ml-3'] || props['mx-3']) && 'margin-left: 3%'};
    ${(props['mr-3'] || props['mx-3']) && 'margin-right: 3%'};

    ${props['mt-4'] && 'margin-top: 4%'};
    ${props['mt-5'] && 'margin-top: 5%'};

    ${(props['mt-12'] || props['my-12']) && 'margin-top: 12%'};
    ${(props['mb-12'] || props['my-12']) && 'margin-bottom: 12%'};
    ${(props['ml-12'] || props['mx-12']) && 'margin-left: 12%'};
    ${(props['mr-12'] || props['mx-12']) && 'margin-right: 12%'};
  }
`;

export const padding = (props) => css`
  @media screen and (min-width: 0) {
    ${(props['p-0'] || props['pt-0'] || props['py-0']) && 'padding-top: 0'};
    ${(props['p-0'] || props['pb-0'] || props['py-0']) && 'padding-bottom: 0'};
    ${(props['p-0'] || props['pl-0'] || props['px-0']) && 'padding-left: 0'};
    ${(props['p-0'] || props['pr-0'] || props['px-0']) && 'padding-right: 0'};

    ${(props['p-6'] || props['pt-6'] || props['py-6']) && 'padding-top: 6%'};
    ${(props['p-6'] || props['pb-6'] || props['py-6']) && 'padding-bottom: 6%'};
    ${(props['p-6'] || props['pl-6'] || props['px-6']) && 'padding-left: 6%'};
    ${(props['p-6'] || props['pr-6'] || props['px-6']) && 'padding-right: 6%'};

    ${(props['pt-8'] || props['py-8']) && 'padding-top: 8%'};
    ${(props['pb-8'] || props['py-8']) && 'padding-bottom: 8%'};
    ${(props['pl-8'] || props['px-8']) && 'padding-left: 8%'};
    ${(props['pr-8'] || props['px-8']) && 'padding-right: 8%'};

    ${(props['pt-9'] || props['py-9']) && 'padding-top: 9%'};
    ${(props['pb-9'] || props['py-9']) && 'padding-bottom: 9%'};
    ${(props['pl-9'] || props['px-9']) && 'padding-left: 9%'};
    ${(props['pr-9'] || props['px-9']) && 'padding-right: 9%'};
  }
`;
