
import React, { useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import IcomoonReact from 'icomoon-react/dist/IcomoonReact';
import { useSelector, useDispatch } from 'react-redux';
import { useKeycloak } from 'react-keycloak';
import { api } from 'dicomweb-client';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useBeforeunload } from 'react-beforeunload';
import { StudyUploadItem } from './StudyUploadItem';
import {
  StyledStudyUploadWidget,
  StyledStudyUploadWidgetHeader,
  StyledStudyUploadWidgetContent,
} from './styled/StyledStudyUploadWidget';
import icons from '../../../icons/cloud.json';
import iconsInfo from '../../../icons/iconsInfo';

export const StudyUploadWidget = () => {
  const maxConcurrentUploads = 2;
  const [isMinimized, setIsMinimized] = useState(false);
  const [currentUploads, setCurrentUploads] = useState(0);
  const [keycloak] = useKeycloak();
  const themeContext = useContext(ThemeContext);
  const uploadingStudies = useSelector((state) => state.uploadingStudies);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // eslint-disable-next-line consistent-return
  useBeforeunload(() => {
    if (uploadingStudies.length > 0) {
      return 'There are studies being uploaded';
    }
  });

  const countPendingFiles = () => {
    let pendingFiles = 0;
    uploadingStudies.forEach((uploadingStudy) => {
      pendingFiles += uploadingStudy.files.length - uploadingStudy.nextFileIndex;
    });
    return pendingFiles;
  };

  const getDicomWebClient = () => (
    new api.DICOMwebClient({
      url: process.env.GATSBY_DICOM_WEB_URL,
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    })
  );

  const uploadNextFile = async () => {
    setCurrentUploads((prevCurrentUploads) => (prevCurrentUploads + 1));
    const study = uploadingStudies.find((element) => (element.nextFileIndex <= element.files.length));
    dispatch({ type: 'UPDATE_STUDY_UPLOAD_FILE_INDEX', study });
    const client = getDicomWebClient();
    try {
      await client.storeInstances({
        datasets: [study.files[study.nextFileIndex]],
        studyInstanceUID: study.studyInstanceUid,
      });
    } catch (e) {
      toast(t('upload.study.instance.error', { studyDescription: study.description }), { className: 'toast-error' });
    }
    dispatch({ type: 'UPDATE_STUDY_UPLOAD_FILES_COMPLETED', study });
    setCurrentUploads((prevCurrentUploads) => (prevCurrentUploads - 1));
  };

  if (countPendingFiles() > 0 && currentUploads < maxConcurrentUploads) {
    uploadNextFile().then();
  }

  if (uploadingStudies.length === 0) {
    return null;
  }

  return (
    <StyledStudyUploadWidget>
      <StyledStudyUploadWidgetHeader onClick={() => { setIsMinimized(!isMinimized); }}>
        {t('uploading.n.studies', { count: uploadingStudies.length })}
        <IcomoonReact
          iconSet={icons}
          color={themeContext.colors.white}
          size={14}
          icon={isMinimized ? iconsInfo.arrow_up.name : iconsInfo.arrow_down.name}
        />
      </StyledStudyUploadWidgetHeader>
      <StyledStudyUploadWidgetContent isMinimized={isMinimized}>
        {
          uploadingStudies.map(((uploadingStudy) => (
            <StudyUploadItem key={uploadingStudy.studyInstanceUid} uploadingStudy={uploadingStudy} />
          )))
        }
      </StyledStudyUploadWidgetContent>
    </StyledStudyUploadWidget>
  );
};
