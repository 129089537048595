import styled from 'styled-components';

export const Span = styled.span`
  margin-top: 4px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1em')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '500')};
  ${(props) => (props.color ? `color: ${props.color};` : '')}
  ${(props) => (props.float ? `float: ${props.float};` : '')}
  ${(props) => (props.minWidth ? `min-width: ${props.minWidth};` : '')}
`;

export const SpanEllipsis = styled.span`
  display: ${(props) => (props.display ? props.display : 'inline-block')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '8em')};
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
`;

export const ExtraBold = styled(Span)`
  font-weight: ${(props) => props.theme.font.weight.xbold};
`;

export const Strong = styled(Span)`
  font-weight: ${(props) => props.theme.font.weight.bold};
`;

export const DivCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${(props) => (props.minHeight ? props.minHeight : '0')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1em')};
`;
