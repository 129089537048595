
import styled from 'styled-components';

export const LogoWrapper = styled.div`
  min-height: 100px;
  position: relative;

  & > img {
    max-width: 190px;
    max-height: 60px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
`;
