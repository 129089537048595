import styled from 'styled-components';

import { sizes } from '../../../styles/variables';
import { BoldTextButton } from '../../Common/styled/TextButton';

export const Header = styled.header`
  width: 100%;
  height: 74px;
  position: relative;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 2px ${(props) => props.theme.colors.primary};
  box-sizing: border-box;
  box-shadow: 0 0 .75em #00000057;
  z-index: 9;

  ${BoldTextButton} {
    margin-right: auto;
  }

  // @media (max-width: ${sizes.xsMax}) {
    // height: 64px;
    // padding: 8px;
  // }

  @media (min-width: ${sizes.smMin}) and (max-width: ${sizes.mdMax}) {
    height: 64px;
  }

  @media (max-width: ${sizes.xsMax}) {
    height: 64px;
  }

  // @media (min-width: ${sizes.mdLgMin}) {
  //   ${({ fixSidebar, theme }) => fixSidebar && `
  //     width: calc(100vw - ${theme.sidebarWidth});
  //   `}
  // }
`;

export const HeaderGroup = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
