import React from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from 'react-keycloak';
import { ExitToApp } from '@material-ui/icons';

import { InnerHTML } from '../components/Common/InnerHTML';
import { AlertUI } from '../componentsUI/Alert';
import { ButtonType } from '../componentsUI/SectionBar';
import { ButtonUI } from '../componentsUI/Button';

export const networkErrorParse = (error) => (
  error && (
    error.graphQLErrors && error.graphQLErrors.length
      ? (error.graphQLErrors[0].debugMessage || error.graphQLErrors[0].message)
      : error.message
  )
);

export const graphQLErrorParse = (error) => (
  error && (
    error.graphQLErrors && error.graphQLErrors.length
      && (error.graphQLErrors[0].debugMessage || error.graphQLErrors[0].message)
  )
);

export const WelcomeErrorParse = ({ error }) => {
  const { t } = useTranslation();
  const [keycloak] = useKeycloak();
  const strings = error.split('|');

  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    keycloak.logout({ redirectUri: process.env.GATSBY_APP_BASE_URL });
  };

  return (
    <>
      <AlertUI severity="warning" title={t('warning')}>
        <InnerHTML content={t(strings[0], { institutionName: strings[1] })} />
      </AlertUI>
      <br />
      <AlertUI severity="info" title={t('info')}>
        <InnerHTML content={t('no.more.hospitals.with.same.institution.name.allowed')} />
        <ButtonUI variant="contained" size="small" color="primary" onClick={logout}>
          {t('log.out')}
          &nbsp;
          <ExitToApp />
        </ButtonUI>
      </AlertUI>
    </>
  );
};
