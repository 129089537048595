// @flow

import React, { useState } from 'react';

import i18n from 'i18next';
import { Tooltip, Zoom } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getLocaleDefault, LocaleList } from '../../../utils/LocaleUtils';
import { TextFieldSelectUI } from '../../../componentsUI/TextFieldSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      maxHeight: 40.9,
      borderRadius: 6,
      [theme.breakpoints.down('xs')]: {
        borderRadius: 4,
      },
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 27,
      paddingLeft: 12,
      [theme.breakpoints.down('xs')]: {
        paddingTop: 7,
        paddingBottom: 7,
      },
    },
    '& .MuiSvgIcon-root.MuiSelect-icon': {
      right: 4,
    },
  },
}));

export default () => {
  const styles = useStyles();
  const [selectProps, setSelectProps] = useState({
    values: { language: getLocaleDefault() },
    errors: { language: null },
  });

  const handleChange = (language) => {
    i18n.changeLanguage(language).then(() => setSelectProps({
      values: { language },
      errors: { language: null },
    }));
  };

  return (
    <Tooltip arrow title="Lang..." placement="left" TransitionComponent={Zoom}>
      <div>
        <TextFieldSelectUI
          name="language"
          onClick={(option) => handleChange(option)}
          props={selectProps}
          classes={styles}
          options={LocaleList}
          variant="outlined"
          type="language"
        />
      </div>
    </Tooltip>
  );
};
