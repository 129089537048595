/**
 * @param obj
 * @returns {boolean}
 */
export const isEmpty = (obj) => !(!!obj && Object.keys(obj).length);

/**
 * @param obj Object
 * @returns {boolean}
 */
export const isEmptyOrAllNull = (obj) => {
  if (!!obj && Object.keys(obj).length) {
    // eslint-disable-next-line no-restricted-syntax
    for (const value of Object.values(obj)) {
      if (value !== null) {
        return false;
      }
    }
  }
  return true;
};

/**
 * @param object Object ...where we want get a specific value
 * @param path String the object attribute path
 *
 * @returns {*}
 */
export const objectPathValue = (object, path) => path
  .replace(/[\[]+/g, '.')
  .replace(/[\]]+/g, '')
  .split('.')
  .reduce((o, i) => o && o[i], object);

/**
 * @param objectArray Array of Objects provided by the GraphQL query
 * @param options Array of Objects with the fields that allow to sort the GraphQL data,
 *        Objects must have the following structure:
 *        { label: 'label', id: 'object.path', field: 'FIELD_NAME', direction: 'ASC' or 'DESC' },
 * @param field String the selected index ('FIELD_NAME') to sort the GraphQL data
 * @param direction String should be either 'ASC' or 'DESC'
 *
 * @returns {Array|null}
 */
export const sortGraphQueryList = (objectArray, options, field, direction = 'ASC') => {
  const option = options.find((n) => n.field === field);
  const sort = direction === 'ASC' ? 1 : -1;

  /**
   * @param oa Array: the Array of Objects we want to sort
   * @param path String: the Object attribute we want to sort by, it can be 'index', 'index.subIndex', etc.
   * @param ref Integer: if the array should be sorted ASC(1) or DESC(-1)
   *
   * @returns {array|null}
   */
  const sortObjectArray = (oa, path, ref = 1) => {
    if (!(!!oa && oa.length)) return null;
    return [...oa].sort(
      (a, b) => (objectPathValue(a, path) > objectPathValue(b, path) && ref) || -ref,
    );
  };

  return option && option.id && sortObjectArray(objectArray, option.id, sort);
};
