// @flow

import React from 'react';
import { useSelector } from 'react-redux';

import defaultHospitalLogo from '../../images/logo.svg';

export default () => {
  const hospitalLogo = useSelector((state) => state.hospital.logo);

  return (
    <img src={hospitalLogo || defaultHospitalLogo} alt="logo" />
  );
};
