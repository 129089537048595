import { createGlobalStyle } from 'styled-components';

import { sizes } from '../styles/variables';

const GlobalStyle = createGlobalStyle`
  html {
    @media screen and (min-width: ${sizes.lgMin}) {
      font-size: 65%;
    }
  }

  body {
    @media screen and (max-width: ${sizes.mdMax}) {
      font-size: 1.3rem;
    }
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.black};
  }

  .danger {
    color: ${(props) => props.theme.colors.danger};
  }
`;

export default GlobalStyle;
