const provideDownloadedResponse = (res, study, prefix) => res.blob().then((blob) => {
  const a = document.createElement('a');
  a.style = 'display: none';
  document.body.appendChild(a);

  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = `${prefix}_${study.title}`;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
});

export const DownloadDicomFile = (study, keycloak) => (
  fetch(`${process.env.GATSBY_CLOUD_API_BASE_URL}/dicom-web/studies/${study.studyInstanceUid}?accept=application/zip`, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  })
);

export const DownloadDicomCD = async (study, keycloak) => (
  fetch(`${process.env.GATSBY_CLOUD_API_BASE_URL}/dicom-cd/studies/${study.studyInstanceUid}?accept=application/zip;transfer-syntax=*&dicomdir=true`, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  })
);

export const ProvideDownloadedBlob = (blob, filename) => {
  const a = document.createElement('a');
  a.style = 'display: none';
  document.body.appendChild(a);

  a.style = 'display: none';
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};

export const RejectDicomStudy = async (study, keycloak) => (
  fetch(`${process.env.GATSBY_CLOUD_API_BASE_URL}/dicom-web/studies/${study.studyInstanceUid}/reject/113039^DCM`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      DNT: 1,
    },
    body: {},
  }));


export default DownloadDicomFile;
