import { getSessionStorageJson } from './ReadStorageUtils';

export const getHospitals = () => {
  const hospitalData = getSessionStorageJson('hospitalData');
  if (!hospitalData) return false;

  const { edges } = hospitalData.hospitals;
  return edges.map((edge) => edge.node);
};

export const setHospitalEnvironment = ({ uuid, dispatch, context }) => {
  const hospitals = getHospitals();

  if (!hospitals) return;
  if (!hospitals.length) return;

  const hospital = hospitals.find((h) => h.uuid === uuid)
    ? hospitals.find((h) => h.uuid === uuid)
    : hospitals[0];

  dispatch({ type: 'CHANGE_HOSPITAL', current: hospital });
  context.setPrimaryColor(hospital.mainColor);
};

export const getCurrentPatientUserHospital = (node1, node2, user) => {
  if (!node1) return null;
  if (!node1.userPatients) return null;
  if (!node2) return null;
  if (!node2.patientHospitals) return null;
  if (!user) return null;
  const match = node1.userPatients.filter((patient) => patient.uuid === user.patientUuid);
  if (!match[0]) return null;
  const hospitalUuid = match[0].hospital.uuid;

  return node2.patientHospitals && node2.patientHospitals.edges && node2.patientHospitals.edges
    .map((edge) => edge.node)
    .find((item) => item.uuid === hospitalUuid);
};
