import React from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flex: 'auto',
    marginLeft: 5,
    fontWeight: 700,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '& > a:last-of-type span': {
      fontWeight: 800,
    },
  },
  root: {
    '& .MuiButton-containedSizeLarge': {
      padding: '.25em 1.25em',
    },
  },
}));

export const ApplicationPathNavbarUI = ({ children }) => {
  const styles = useStyles();

  return (
    <Box className={styles.wrapper}>
      {children}
    </Box>
  );
};

export default ApplicationPathNavbarUI;
