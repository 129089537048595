const parseJson = (string, defaultValue) => {
  try {
    return JSON.parse(string);
  } catch (e) {
    return defaultValue;
  }
};

export const getLocalStorageJson = (key, defaultValue = null) => (parseJson(localStorage.getItem(key), defaultValue));
export const getSessionStorageJson = (key, defaultValue = null) => (parseJson(sessionStorage.getItem(key), defaultValue));
