import { dateParse } from './dateTimeUtils';
import ReleaseNotes from '../images/release-notes.yml';

// Set how many days the information about the platform will be displayed after login
const expirationDaysForPlatformNews = 15;

// The number of days is converted to milliseconds to compare with current time
const expirationTime = (days) => (days * 1000 * 60 * 60 * 24);

// Set the last time PlatformUpdate modal pop-up has been seen by user
export const setLocalStorageReleaseNotesRead = () => {
  if (typeof (Storage) === 'undefined') {
    return;
  }
  localStorage.setItem('release-notes-checked-date', new Date().valueOf());
};

// Get the last time PlatformUpdate modal pop-up has been seen by user
const getLocalStorageReadNews = () => {
  if (typeof (Storage) === 'undefined') {
    return 0;
  }
  if (!localStorage.getItem('release-notes-checked-date')) {
    return 0;
  }
  return localStorage.getItem('release-notes-checked-date');
};

// Map the news that should be displayed filtered by the expiration days threshold
const selectNews = (list, days, state) => {
  const news = [];
  const currentDate = new Date().valueOf();

  // Every item date is checked and only added to the array if newer than expiration time
  list.forEach((release) => {
    const id = Object.keys(release)[0];
    const date = new Date(release[id].date);
    const object = {
      version: id,
      date: dateParse(date),
      news: release[id].news,
    };

    if (state && state.type === 'all') {
      news.push(object);
      return;
    }

    const newsDate = date.valueOf();

    if ((newsDate + expirationTime(days)) > currentDate) {
      if (newsDate > getLocalStorageReadNews()) {
        news.push(object);
      }
    }
  });

  return news;
};

export const availableReleaseNotes = (state) => selectNews(ReleaseNotes, expirationDaysForPlatformNews, state);
export const displayUpdateNews = (state) => availableReleaseNotes(state).length > 0;
