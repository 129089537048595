import React from 'react';
import { Menu } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  paper: {
    '& .MuiPaper-root': {
      border: '1px solid #d3d4d5',
    },
  },
}));

export default (props) => {
  const classes = useStyles();
  const { children, id, anchorEl, keepMounted, open, onClose } = props;

  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      keepMounted={keepMounted}
      onClose={onClose}
      open={open}
      className={classes.paper}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {children}
    </Menu>
  );
};
