import { css } from 'styled-components';

import { padding as paddingMixin } from '../../../styles/mixins';
import { sizes } from '../../../styles/variables';

const Base = css`
  ${(props) => paddingMixin(props)}
`;

export const MediumPadding = css`
  padding: 3%;

  @media screen and (max-width: ${sizes.xsMax}) {
    padding: 6%;
  }

  ${Base}
`;

export const Padding = MediumPadding;
