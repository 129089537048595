import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ListItemIcon, ListItemText } from '@material-ui/core';
import { AccountBox } from '@material-ui/icons';
import UserAccountMenuItem from './UserAccountItem';


export default forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { onClick } = props;

  return (
    <Box ref={ref}>
      <UserAccountMenuItem onClick={onClick}>
        <ListItemIcon>
          <AccountBox fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('my.profile')}</ListItemText>
      </UserAccountMenuItem>
    </Box>
  );
});
