import React, { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide } from '@material-ui/core';

import { ImportStudyWidgetList } from './ImportStudyWidgetList';

import {
  GET_IMPORT_DICOM_STUDY_QUEUE,
  GET_IMPORT_DICOM_STUDY_QUEUE_SUMMARY,
} from '../../../queries/RemotePacs/RemotePacs';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 800,
      padding: 15,
      '@media (max-width:600px)': {
        padding: 0,
        margin: '.75em',
      },
      '& > div > h2': {
        fontWeight: 600,
        lineHeight: 1.3,
      },
    },
  },
  min: {
    position: 'absolute',
    left: 2,
    bottom: 2,
    zIndex: 1500,
    fontSize: '1.1875rem',
    fontWeight: 600,
    '& .MuiCircularProgress-root': {
      margin: '2px 0 -4px 8px',
    },
  },
  grid: {
    '& .MuiGrid-container': {
      marginBottom: 0,
      marginTop: 0,
      '&.header': {
        color: '#444',
        borderBottom: 'solid 3px #bbb',
        marginBottom: 3,
        fontWeight: 700,
      },
      '& > .MuiGrid-item': {
        alignItems: 'center',
        '& > .ellipsis': {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
        '&:last-child': {
          fontSize: '.9375em',
          width: 115,
          textAlign: 'right',
        },
      },
      '&:not(:last-child):not(.header)': {
        borderBottom: 'solid 1px #ddd',
      },
    },
  },
}));

export const ImportStudyWidgetItem = ({ localQueue }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const displayQueue = useSelector((state) => state.userInterface.importStudyQueue);
  const [open, setOpen] = useState(false);
  const [importLoopStatus, setImportLoopStatus] = useState(false);

  const timer = (time) => new Promise((response) => setTimeout(response, time));

  const [getImportStatusSummary, { called, loading, data: sData, refetch }] = useLazyQuery(
    GET_IMPORT_DICOM_STUDY_QUEUE_SUMMARY,
    {
      variables: {
        requestUuidList: localQueue.map((item) => item.requestUuid),
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: async () => {
        setImportLoopStatus(false);
        if (!open) return;
        setImportLoopStatus(true);
        await timer(10000);
        refetch().then();
      },
    },
  );

  const { data } = useQuery(
    GET_IMPORT_DICOM_STUDY_QUEUE,
    {
      variables: {
        requestUuidList: localQueue.map((item) => item.requestUuid),
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        if (!open) return;
        if (importLoopStatus === false) getImportStatusSummary();
      },
    },
  );

  const importDicomStudyQueue = data && data.importDicomStudyQueue;
  const importQueue = sData && sData.importQueue;

  useEffect(() => {
    if (!open) return;
    if (!importDicomStudyQueue) return;
    if (importLoopStatus === false) getImportStatusSummary();
  }, [open]);

  const clearQueue = () => {
    localStorage.removeItem('import-study');
    dispatch({ type: 'IMPORT_STUDY_QUEUE', importStudyQueue: { importStudyQueue: false } });
  };

  if (called && !loading && data && displayQueue) {
    if (!importDicomStudyQueue) clearQueue();
    if (!importDicomStudyQueue.length) clearQueue();
  }

  if (!importDicomStudyQueue) return '';
  if (!importDicomStudyQueue.length) return '';

  const onClose = () => setOpen(false);
  const onClick = () => setOpen(true);

  return (
    <>
      {''}
      {displayQueue && (
        <Button
          variant="outlined"
          onClick={onClick}
          className={classes.min}
          disabled={!importDicomStudyQueue}
          style={{ display: open ? 'none' : 'block' }}
        >
          {t('study.import')}
        </Button>
      )}
      <Dialog
        className={classes.dialog}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
      >
        <DialogTitle>
          {t('requested.import.study.queue')}
        </DialogTitle>
        <DialogContent className={classes.grid}>
          <ImportStudyWidgetList
            studies={importDicomStudyQueue}
            instances={importQueue}
            loading={loading}
            onClose={onClose}
          />
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};
