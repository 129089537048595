import React from 'react';
import { MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: '3em',
    color: theme.palette.grey.A400,
    '&.Mui-disabled': {
      color: '#FFFFFFF7',
      backgroundImage: `linear-gradient(to right, ${theme.palette.grey.A400}, ${theme.palette.grey.A100})`,
      lineHeight: 1,
      fontSize: '.9375em',
      fontWeight: '600',
      padding: '.3em .5em',
      borderTop: '1px solid #0000001F',
      borderBottom: '1px solid #0000001F',
    },
    '& .MuiListItemText-root': {
      marginTop: '6px',
    },
    '& .MuiListItemIcon-root': {
      // color: theme.palette.primary.main,
      '& > svg': {
        color: '#666',
        height: '1.125em',
        width: '1.125em',
      },
    },
    '&:hover': {
      // backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.black,
        '& > svg': {
          color: theme.palette.primary.dark,
        },
      },
    },
  },
}));

export default ({ onClick, children, disabled = false }) => {
  const classes = useStyles();

  return (
    <MenuItem className={classes.root} onClick={onClick} disabled={disabled}>
      {children}
    </MenuItem>
  );
};
